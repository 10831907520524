/**
 * Decode access token and return an object
 * @param token 
 * @returns 
 */
export const parseToken = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}
/**
 * Takes an access token and return true if the user has the permission for the given client
 * @param resource Keycloak client to check for permission
 * @param permission name of the client permission
 * @param token the access token
 * @returns 
 */
export const hasResourceRole = (resource: string, permission: string, token: string): boolean => {
  const tokenObj = parseToken(token);
  const resourceAccess = tokenObj?.resource_access;
  if (!resourceAccess) return false;
  const roles = resourceAccess[resource || '']?.roles || [];
  return roles.indexOf(permission) !== -1;
}