
import './Home.css'
// import { bffUrl, appUrl } from "../../common/constants";
import UsersPage from '../users/Users'
import { useAuth } from 'react-oidc-context';
import { hasResourceRole } from '../../common/utils/tokenUtil';


export const Home = () => {
  const auth = useAuth();

  return (<>
    <div className="content">
      {
      (auth.isAuthenticated && hasResourceRole('wildfire-admin-api','Adm_UserList_V',auth.user?.access_token as string)) ?
        <UsersPage /> :
        <NotAuthorized />
      }
    </div>
  </>)
}

/********************************************************************************************************
 * 
 * @returns 
*********************************************************************************************************/
function NotAuthorized(){
  return (
    <p className='notAuthorized'>
      You are currently unable to access this application because the user roles for this application are not assigned to you.
      For access, please contact one of the following: your supervisor, your Area's Regional Data Administrator, 
      your Wildfire Management Branch contact, or Wildfire Applications Support via <a href="mailto:wildfire.applications@gov.ab.ca"> wildfire.applications@gov.ab.ca</a> or +1 780-427-8100
    </p>
  );
}