import { createTheme } from "@mui/material/styles";
import "../index";
/*
  --goa-color-brand-default: #0081a2;
  --goa-color-brand-dark: #005072;
  --goa-color-brand-light: #c8eefa;
  --goa-color-interactive-default: #0070c4;
  --goa-color-interactive-hover: #004f84;
  --goa-color-interactive-error: #ec040b;
  --goa-color-interactive-focus: #feba35;
  --goa-color-interactive-disabled: #80b7e1;
  --goa-color-text-default: #333333;
  --goa-color-text-secondary: #666666;
  --goa-color-text-light: #ffffff;
  --goa-color-text-disabled: #666666;
  --goa-color-info-default: #004a8f;
  --goa-color-info-light: #aac9e7;
  --goa-color-info-dark: #003b70;
  --goa-color-info-background: #eff8ff;
  --goa-color-warning-default: #f9ce2d;
  --goa-color-warning-light: #ffeab6;
  --goa-color-warning-dark: #bf8d23;
  --goa-color-warning-background: #fff6e5;
  --goa-color-emergency-default: #da291c;
  --goa-color-emergency-light: #fbd1ce;
  --goa-color-emergency-dark: #a91a10;
  --goa-color-emergency-background: #fff1f2;
  --goa-color-success-default: #006f4c;
  --goa-color-success-light: #cce4dc;
  --goa-color-success-dark: #00563a;
  --goa-color-success-background: #eef9f3;
  --goa-color-greyscale-100: #f1f1f1;
  --goa-color-greyscale-200: #dcdcdc;
  --goa-color-greyscale-400: #adadad;
  --goa-color-greyscale-500: #949494;
  --goa-color-greyscale-600: #858585;
  --goa-color-greyscale-700: #666666;
  --goa-color-greyscale-black: #333333;
  --goa-color-greyscale-white: #ffffff;
  --goa-font-weight-regular: 400;
  --goa-font-weight-medium: 500;
  --goa-font-weight-bold: 700;
  --goa-font-size-1: .75rem;
  --goa-font-size-2: .875rem;
  --goa-font-size-3: 1rem;
  --goa-font-size-4: 1.125rem;
  --goa-font-size-5: 1.25rem;
  --goa-font-size-6: 1.375rem;
  --goa-font-size-7: 1.5rem;
  --goa-font-size-8: 2rem;
  --goa-font-size-9: 2.25rem;
  --goa-font-size-10: 3rem;
  --goa-space-none: 0rem;
  --goa-space-3xs: .125rem;
  --goa-space-2xs: .25rem;
  --goa-space-xs: .5rem;
  --goa-space-s: .75rem;
  --goa-space-m: 1rem;
  --goa-space-l: 1.5rem;
  --goa-space-xl: 2rem;
  --goa-space-2xl: 3rem;
  --goa-space-3xl: 4rem;
  --goa-space-4xl: 8rem;
  --goa-border-radius-none: 0rem;
  --goa-border-radius-m: .25rem;
  --goa-opacity-background-modal: 50%;
  --goa-opacity-background-loading: 90%;
  --goa-line-height-1: 1.25rem;
  --goa-line-height-2: 1.5rem;
  --goa-line-height-3: 1.75rem;
  --goa-line-height-4: 2rem;
  --goa-line-height-5: 2.5rem;
  --goa-line-height-6: 2.75rem;
  --goa-line-height-7: 3.5rem;
  --goa-line-height-05: 1.125rem;
  --goa-font-family-sans: acumin-pro-semi-condensed, helvetica-neue, arial, sans-serif;
  --goa-font-family-number: roboto-mono, monospace;
  --goa-typography-heading-xs: 700 1rem/1.5rem acumin-pro-semi-condensed, helvetica-neue, arial, sans-serif;
  --goa-typography-heading-s: 700 1.125rem/1.75rem acumin-pro-semi-condensed, helvetica-neue, arial, sans-serif;
  --goa-typography-heading-m: 400 1.5rem/2rem acumin-pro-semi-condensed, helvetica-neue, arial, sans-serif;
  --goa-typography-heading-l: 400 2.25rem/2.75rem acumin-pro-semi-condensed, helvetica-neue, arial, sans-serif;
  --goa-typography-heading-xl: 700 3rem/3.5rem acumin-pro-semi-condensed, helvetica-neue, arial, sans-serif;
  --goa-typography-body-xs: 400 .875rem/1.25rem acumin-pro-semi-condensed, helvetica-neue, arial, sans-serif;
  --goa-typography-body-s: 400 1rem/1.5rem acumin-pro-semi-condensed, helvetica-neue, arial, sans-serif;
  --goa-typography-body-m: 400 1.125rem/1.75rem acumin-pro-semi-condensed, helvetica-neue, arial, sans-serif;
  --goa-typography-body-l: 400 1.5rem/2rem acumin-pro-semi-condensed, helvetica-neue, arial, sans-serif;
  --goa-typography-button-default: 400 1.125rem/1.25rem acumin-pro-semi-condensed, helvetica-neue, arial, sans-serif;
  --goa-typography-number-m: 500 1.125rem/1.75rem roboto-mono, monospace;
  --goa-border-width-s: 1px;
  --goa-border-width-m: 2px;
  --goa-border-width-l: 3px;
  --goa-letter-spacing-button: .0125rem;
  --goa-icon-size-s: 1rem;
  --goa-icon-size-m: 1.25rem;
  --goa-icon-size-l: 1.5rem;
  --goa-shadow-modal: 6px 6px 6px 0px rgba(0,0,0,.16);
*/

export const muiGoATheme = createTheme({
  spacing: 2,
  palette: {
    mode: "light",
    primary: {
      //  --goa-color-interactive-default: #0070c4;
      main: "#0070c4",
    },
    secondary: {
      // --goa-color-success-default: #006f4c;
      main: "#006f4c",
    },
    error: {
      // --goa-color-interactive-error: #ec040b;
      main: "#ec040b",
    },
    success: {
      // --goa-color-success-default: #006f4c;
      main: "#006f4c",
    },
    info: {
      // --goa-color-info-default: #004a8f;
      main: "#004a8f",
    },
    warning: {
      // --goa-color-warning-default: #f9ce2d;
      main: "#f9ce2d",
    },
  },
  components: {
    // CTRL + SPACE to find the component you would like to override.
    // For most of them you will need to adjust just the root...
    MuiTextField: {
      styleOverrides: {
        root: {
          border: "8px",
          "& label": {
            color: "#3E68A8",
          },
          "& label.Mui-focused": {
            color: "#3E68A8",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "#666666",
          },
          "& .MuiOutlinedInput-root input:disabled": {
            backgroundColor: "#ccc"
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#666666",
              borderWidth: "2px",
            },
            "&:hover fieldset": {
              borderColor: "#004f84",
              borderWidth: "0.15rem",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#feba35",
            },
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          "& .MuiPaper-root": {
            backgroundColor: "#fff",
            color: "var(--goa-color-text-default)"
          },
        },
      },
    },
  },
});
