import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import "@abgov/web-components/index.css";
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider } from '@mui/material/styles';
import { muiGoATheme } from './common/mui-theme';
import { AuthProvider } from "react-oidc-context";
import { oidcConfig } from './common/kcConfig';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <>
    <Helmet>
      <script
        type="module"
        src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"
      ></script>
      <script
        src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js"
      ></script>
    </Helmet>
    <AuthProvider {...oidcConfig}>
      <>
        <React.StrictMode>
          <QueryClientProvider client={queryClient}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ThemeProvider theme={muiGoATheme}>
                <App />
              </ThemeProvider>
            </LocalizationProvider>
          </QueryClientProvider>
        </React.StrictMode>
      </>
    </AuthProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
/*
{
  "realm": "1405810f-b8a8-4eb6-b22a-e9e89347420b",
  "auth-server-url": "https://access-uat.alberta.ca/auth",
  "ssl-required": "external",
  "resource": "wildfire-system-administration",
  "public-client": true,
  "confidential-port": 0
}
*/