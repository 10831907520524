import './WdpTable.scss'
import { useState, useEffect, useCallback, useRef } from "react";
import { GoATableSortHeader, GoATable,  GoATableSortDirection  } from "@abgov/react-components"
interface colHeader {
  text: string;
  name: string
  ref: React.MutableRefObject<HTMLTableHeaderCellElement | null>,
  direction: GoATableSortDirection,
  initWidth:string
}

const createHeaders = (headers: any[]) => {

  if(headers === undefined || headers.length === 0){
    return null;
  }
  return headers.map((item: any): colHeader => ({
      text: item.text,
      name: item.name,
      ref: useRef<HTMLTableHeaderCellElement | null>(null),
      direction: item.sortDir ?? 'none' ,//((item === 'Username ( email )') ? 'none' : 'none'),
      initWidth: item.width
    }));
}

export const WdpTable = (props: any) => {

  // state for table resize functionality
  const [tableHeight, setTableHeight] = useState<string | number>('auto');
  const [activeIndex, setActiveIndex] = useState(null);
  const [tableElement, setTableElement] = useState<HTMLTableElement>();
  // init table header
  const columns = createHeaders(props.tableHeaders);
  const tableWrap = useRef<HTMLDivElement | null>(null);

  // mouseMove
  const mouseMove = useCallback((e: MouseEvent) => {
    if (tableWrap.current != null && columns && columns?.length !== 0)
    {
      const gridColumns = columns.map((col, i) => {
        if (i === activeIndex) {
          // Calculate the column width
          // let x = e.pageX;
          let width = 0;
          if (col.ref.current) {
            const rc = col.ref.current.getBoundingClientRect();
            let delta = e.clientX - rc.right;
            width = col.ref.current.offsetWidth + delta;
          }
          if (width >= 120) {
            return `${width}px `;
          }
        }
        // Otherwise return the previous width (no changes)
        return `${col.ref.current ? col.ref.current.offsetWidth : 0}px `;
      });

      // Assign the px values to the table
    if (tableElement != null) {
        tableElement.style.gridTemplateColumns = `${gridColumns.join(' ')}`;
      }
    }

  }, [activeIndex, columns, tableElement]);

  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', removeListeners);
  }, [mouseMove]);

  /*********************************************************************************
   *  mouse Down event
   * @param index 
   ********************************************************************************/
  const mouseDown = (index: any) => {
    setActiveIndex(index);
    
    // samer this is temp 
    /*if (tableWrap.current != null) {
      if (tableElement != null) {
        setTableHeight(tableElement.offsetHeight);
      }
    }*/
  }
  /*******************************************************************************
   * mouseup event
  ********************************************************************************/
  const mouseUp = useCallback(() => {
    setActiveIndex(null);
    removeListeners();
  }, [setActiveIndex, removeListeners]);

  /*******************************************************************************
   * calc and set table hight after this comp got mounted 
  ********************************************************************************/
  useEffect(() => {
    // set divider height ..same a the table 
    if (activeIndex !== null) {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp);
    }
    if (tableWrap.current != null) {
      if (tableElement != null) {
        setTableHeight(tableElement.offsetHeight);
       // console.log('TH:' + tableElement.offsetHeight);
      }
    }
    return () => {
      removeListeners();
    }
  }, [activeIndex, mouseMove, mouseUp, removeListeners, tableElement, setTableElement, columns]);
  
  /*******************************************************************************
   * calc and set table hight after this comp got mounted 
   * this is a JUST ONCE EFFECT
  ********************************************************************************/
  useEffect(() => {
  //  some styling overwrite
  if (tableWrap.current != null) {
    setTableElement(tableWrap.current.getElementsByTagName('table')[0]);
    if (tableElement != null && columns && columns.length !== 0) {
      setTableHeight(tableElement.offsetHeight);

      // init table columns
      // Assign the px values to the table
      const gridColumns = columns.map((col, i) => {
        if(col.ref.current){
          return `${col.initWidth} `;
        }
        return "2px ";
      });
      tableElement.style.gridTemplateColumns = `${gridColumns.join(' ')}`;
    }
  }
    // 1 - make TD  padding same as TH (Goa) padding
    tableElement?.classList.add("padding");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[tableElement]);
  /*******************************************************************************
   * 
   * @returns 
  ********************************************************************************/
  function populatetableHeader() {
    if(columns === null || columns === undefined || columns?.length === 0){
      return ;
    }
    return (
      <tr>
        {
          columns?.map((col: colHeader, i: number) => {
            return (
              <th ref={col.ref} key={col.text}>

                <div
                  style={{ height: tableHeight }}
                  onMouseDown={() => { mouseDown(i) }}
                  className={`resize-handle ${activeIndex === i ? 'active' : 'idle'}`}
                ></div>
                <GoATableSortHeader name={col.name} direction={col.direction}><span>{col.text}</span></GoATableSortHeader>
              </th>
            )
          }
          )
        }
      </tr> 
    )
  }
  /*******************************************************************************
   * 
   * @param users 
   * @returns 
   *******************************************************************************/
  return (
    <div id={props.id} className="table-wrapper" ref={tableWrap}>
      <GoATable  onSort={props.sortData? props.sortData():null} width="100%" mb="xl">
        <thead >
        {
          populatetableHeader()
        }
        </thead>
        
        <tbody>
          {
            props.populatetableData()
          }
        </tbody>
      </GoATable>
    </div>
  )
};
export default WdpTable;