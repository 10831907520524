import './roleList.scss'
import { useState, useEffect, useRef, useCallback } from "react";
import { GoAInputText, GoABlock, GoADropdown, GoADropdownItem, GoASpacer, GoAPagination, GoAIconButton, GoATable, GoATableSortDirection, GoACircularProgress, GoATableSortHeader } from "@abgov/react-components"
import { Link } from "react-router-dom";
// import { UserInfo } from '../../../common/models/userInfo'
import ApiService from "../../../services/ApiService";
// import { WdpTable } from '../../../components/wdpTable/WdpTable';
import { wdpUtil } from "../../../common/utils/util";
import { useAuth } from 'react-oidc-context';
import dayjs from 'dayjs';
const width = 'minmax(min-content,1fr)';
const defSortDir: GoATableSortDirection = 'asc'
const tableHeaders = [
    { text: "Roles", name: 'roleName', width: `${width}`, sortDir: defSortDir },
    { text: "Created(required)", name: 'roleStartDate', width: `${width}` },
    { text: "Expired", name: 'roleEndDate', width: `${width}` },


];
const minSearchChars = 3;
export const RoleList = () => {
    const [abortControllers, setAbortControllers] = useState<AbortController[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // authentication object
    const auth = useAuth();
    // main dataset coming from the backend
    const [mainUsersList, setMainUsers] = useState(Array<any>);

    // page number
    const pageNo = useRef(1);
    //count per page
    const perPage = useRef(10);
    // total in the DB
    const totalRowsCount = useRef(0);

    //const [sortBy,setSortBy]  = useState('email');
    //const [sortDir,setSortDir] = useState<GoATableSortDirection>('asc');
    const sorting = useRef<{ by: string, dir: GoATableSortDirection }>({ by: 'roleName', dir: 'asc' });


    const [previewUsersList, setPreviewUsersList] = useState(Array<any>);

    const [showPreview, setShowPreview] = useState(false);
    const searchStr = useRef('');
    // preview Table
    const previewTableWrapElement = useRef<HTMLDivElement | null>(null);
    const searchWrap = useRef<HTMLDivElement | null>(null);

    /****************************************************************************************
    // Load User call back function
    ****************************************************************************************/
    const loadRoles = useCallback((preview: boolean) => {

        // parameters
        const _crit = searchStr.current;
        const _sortCol = sorting.current.by;
        const _sortDir = sorting.current.dir;
        const _pageNo = pageNo.current;
        const _perPage = perPage.current;

        console.log(_perPage, _pageNo, _sortCol, _sortDir);
        if (!preview) {
            setIsLoading(true);
        }
        // abort ongoing request
        abortControllers.forEach(controller => controller.abort());
        setAbortControllers([]);

        const controller = new AbortController();
        setAbortControllers([...abortControllers, controller]);

        ApiService.getRoleDefs(auth.user?.access_token as string, _crit, _sortCol, _sortDir, _pageNo, _perPage, controller).then((res: any) => {
            if (res != null && res.rows != null && Array.isArray(res.rows)) {

                if (preview) {
                    setPreviewUsersList(res.rows);
                    setShowPreview(res.rows.length !== 0);
                }
                else {
                    totalRowsCount.current = res.count;
                    // save in the main list
                    setMainUsers(res.rows);
                    // save current page
                    //setPageUsers(res.slice(0, perPage))
                    setShowPreview(false);
                }
            } else {
                setShowPreview(false);
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => { setIsLoading(false) });
    }, [abortControllers, auth.user?.access_token]);

    /****************************************************************************************
     *  get the data from the backend On sorting and ,pagination
    /****************************************************************************************/
    useEffect(() => {
        loadRoles(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    /****************************************************************************************
    *  get the data from the backend On Searching
    /****************************************************************************************/
    function OnSearchChange(value: string) {

        searchStr.current = value;
        if (searchStr.current.length === 0) {
            setPreviewUsersList([]);
            setShowPreview(false);
            // reset sorting and reload
            sorting.current = { by: 'roleName', dir: 'asc' };
            loadRoles(false);

        } else if ((searchStr.current.length < minSearchChars)) {
            setPreviewUsersList([]);
        } else if ((searchStr.current.length >= minSearchChars)) {
            pageNo.current = 1;
            loadRoles(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    /****************************************************************************************
     * styling after every rendering
    *****************************************************************************************/
    useEffect(() => {
        //  GoA styling 
        //let elem = document.getElementsByTagName getElementsByClassName('trailing-content')[0];
        const page = document.getElementsByClassName('users-content')[0];
        if (wdpUtil.isNullorUndefined(page))
            return;
        var elems = page.querySelectorAll('[slot="trailingContent"]');
        (elems[0] as HTMLElement).style.backgroundColor = 'transparent';// setAttribute("style","background-color:transparent");
        (elems[0] as HTMLElement).style.borderWidth = '0px';
        var wrap = searchWrap.current;
        if (wrap !== undefined && wrap instanceof HTMLDivElement) {
            const w = wrap.clientWidth;
            // get search Inputfield position
            if (previewTableWrapElement.current) {
                if (previewTableWrapElement.current!.style.visibility === '' || previewTableWrapElement.current!.style.visibility === 'hidden') {
                    //previewTableWrapElement.current!.style.left = `${Math.floor(wrap.clientLeft - 300)}px`;
                    //previewTableWrapElement.current!.style.top = `${Math.floor(wrap.clientTop + 55)}px`;
                    //previewTableWrapElement.current!.style.right = `0px`;
                    //previewTableWrapElement.current!.style.bottom = `0px`;
                    previewTableWrapElement.current!.style.width = `${Math.floor(w)}px`;
                    previewTableWrapElement.current!.style.minWidth = `${Math.floor(w)}px`;
                }
            }
            //var inp = searchWrap.current.getElementsByTagName('goa-input')[0];
        }
    });
    /****************************************************************************
     * 
     * @param newPage 
    ****************************************************************************/
    function OnPaginationChange(newPage: any) {

        if (newPage >= 1) {
            //setPageNo(newPage);
            pageNo.current = newPage;
            loadRoles(false);
        }
    }
    /****************************************************************************
     * 
     * @param name 
     * @param value 
    ****************************************************************************/
    function changePerPage(name: any, value: any) {
        const newPerPage = parseInt(value, 10);
        // const offset = (pageNo - 1) * newPerPage;
        // const _users = mainUsersList.slice(offset, offset + newPerPage);
        // setPageUsers(_users);
        //setPerPage(newPerPage);
        if (newPerPage >= 1) {

            // reset page No .back to 1
            pageNo.current = 1;


            perPage.current = newPerPage;
            loadRoles(false);
        }
    }
    /**********************************************************************************
     * @param sortBy 
     * @param sortDir 
    ***********************************************************************************/
    function sortData(_sortBy: string, _sortDir: number) {
        sorting.current = { by: _sortBy, dir: _sortDir === 1 ? "asc" : "desc" };
        // reset page No .back to 1
        pageNo.current = 1;
        loadRoles(false);

    }
    /**********************************************************************************
     * 
     * @param users 
     * @returns 
    ***********************************************************************************/
    // function populatetableData(roles: any): any[] {
    //     if (wdpUtil.isNullorUndefined(roles) || !Array.isArray(roles) || roles.length === 0) {
    //         return []
    //     }
    //     return (
    //         roles.map((role, i) =>
    //             <tr key={role.roleId}>
    //                 <td><Link to={`/role-managemanet/${role.id}`}><span>{role.roleName}</span></Link></td>
    //                 <td><span>{role.roleStartDate}</span></td>
    //                 <td><span>{role?.roleEndDate}</span></td>
    //             </tr>
    //         )
    //     );
    // }
    /**********************************************************************************
     * 
    ***********************************************************************************/
    function populatePreviewtableData(): any[] {
        if (wdpUtil.isNullorUndefined(previewUsersList) || !Array.isArray(previewUsersList) || previewUsersList.length === 0) {
            return []
        }
        return (
            previewUsersList.map((role, i) =>
                <tr key={role.id}>
                    <td><Link to={`/role-management/${role.id}`}><span>{role.roleName}</span></Link></td>
                    <td><span>{role.firstName} {role?.middleName} {role.lastName}</span></td>
                </tr>
            )
        );
    }
    /***************************************************************************************
     * 
     * @param name 
     * @param value 
     **************************************************************************************/
    function onSearchInputChange(name: string, value: string) {
        // event without a change in the content (special characters, arrows, ...)
        if (searchStr.current === value) {
            return;
        }
        OnSearchChange(value);
    }

    /****************************************************************************************
     * 
    *****************************************************************************************/
    function onSearchBtn() {
        // take a copy
        const temp = wdpUtil.deepCopy(previewUsersList);
        setMainUsers(temp);
        totalRowsCount.current = temp.length;
        setShowPreview(false);
    }
    /****************************************************************************************
     * 
    *****************************************************************************************/
    function onCloseSearchBtn() {
        setPreviewUsersList([]);
        setShowPreview(false);
        // loadUsers("", false);
        OnSearchChange('');
    }
    /****************************************************************************************
     * 
    *****************************************************************************************/
    function populateSearchResTitle() {
        if (searchStr && searchStr.current.length >= minSearchChars && !isLoading) {
            // search preview visible ?
            return (<div className='h3'>{previewUsersList?.length} result for '{searchStr.current}'</div>);
        } else {
            return (<div></div>);
        }
    }

    function handleKeyEvent(event: any) {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
            event.preventDefault();
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
            onSearchBtn();
        }
    }
    ///////////////////////////////////////////////////////////////////////////
    // html part
    return (
        <div className='users-content'>
            <section className='title-section'>
                <h2 className='title'>Role Management</h2>
                <Link className="link-button" to="/role-management/new"> Create new role +</Link>
            </section>
            <section className='search-section'>
                {
                    populateSearchResTitle()
                }
                <div className='search-wrap' ref={searchWrap} onKeyDown={handleKeyEvent}>
                    <GoAInputText
                        name="search"
                        placeholder="Search by role name"
                        width='70ch'
                        //trailingIcon="search"
                        //leadingIcon="close"
                        trailingContent={
                            <div className='search-buttons-wrap'>
                                {(searchStr === null || searchStr.current.length === 0) ?
                                    <GoAIconButton icon="search" variant="dark" size="medium" disabled title="Search" onClick={function (): void { onSearchBtn() }} />
                                    :
                                    <GoAIconButton icon="search" variant="dark" size="medium" title="Search" onClick={function (): void { onSearchBtn() }} />
                                }

                                {(searchStr === null || searchStr.current.length === 0) ?
                                    <div style={{ width: '26px' }}></div> :
                                    <GoAIconButton icon="close" variant="dark" size="medium" title="Close" onClick={function (): void { onCloseSearchBtn() }} />
                                }


                            </div>
                        }
                        onChange={function (name: string, value: string): void {
                            onSearchInputChange(name, value)
                        }}
                        value={searchStr.current}
                    />
                    {(showPreview) ?
                        <div className='search-preview-wrap' ref={previewTableWrapElement}>
                            <GoATable width="100%" mb="xl">
                                <thead>
                                </thead>
                                <tbody>
                                    {
                                        populatePreviewtableData()
                                    }
                                </tbody>
                            </GoATable>
                        </div>
                        : <div></div>
                    }
                </div>
            </section>
            {(!wdpUtil.isNullorUndefined(mainUsersList) && mainUsersList.length) || true ?
                <div>
                    <GoATable width="100%" onSort={sortData}>
                        <thead>
                            <tr>
                                {tableHeaders.map(header =>
                                    <th key={header.text}>
                                        <GoATableSortHeader name={header.name}>{header.text}</GoATableSortHeader>
                                    </th>)
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {mainUsersList.map(role =>
                                < tr key={role.id}>
                                    <td> <Link to={`/role-management/${role.id}`}>{role.roleName}</Link></td>
                                    <td>{ role.roleStartDate ? dayjs(role.roleStartDate).format('YYYY-MM-DD') : ''}</td>
                                    <td>{ role.roleEndDate ? dayjs(role.roleEndDate).format('YYYY-MM-DD') : ''}</td>
                                </tr>)
                            }
                        </tbody>
                    </GoATable>
                    <GoABlock mt="m" alignment="center">
                        <GoABlock mb="m" alignment="center" gap="m">
                            <span>Show</span>
                            <GoADropdown onChange={changePerPage} value="10" width="8ch">
                                <GoADropdownItem value="10"></GoADropdownItem>
                                <GoADropdownItem value="15"></GoADropdownItem>
                                <GoADropdownItem value="20"></GoADropdownItem>
                            </GoADropdown>
                            <span style={{ whiteSpace: 'nowrap' }}>of {totalRowsCount.current} items</span>
                        </GoABlock>
                        <GoASpacer hSpacing="fill" />
                        <GoAPagination
                            variant={'all'}
                            itemCount={totalRowsCount.current}
                            perPageCount={perPage.current}
                            pageNumber={pageNo.current}
                            onChange={OnPaginationChange}
                        />
                    </GoABlock>
                </div>
                : (!isLoading) && <h3 style={{ width: '50%', margin: '0 auto', textAlign: 'center' }}>There is no result found</h3>
            }
            {/* Circular Progress as a layer  must be child of the page div */}
            {(isLoading) && <GoACircularProgress variant="fullscreen" size="large" message="Loading users' information..." visible={true} />}
        </div>
    );

}