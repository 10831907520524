//import { Box, Fade, Popper } from "@mui/material";
import { ReactNode, useEffect, useRef } from "react";
import './toast.scss'
import { wdpUtil } from "../../common/utils/util";
import { useIsVisible } from "../hooks/useIsVisible";
type ToastProps = {
    open: boolean,
    timeout?: number, // time to hide popper in ms
    onClose?: () => void,
    children?: ReactNode
};

const defualtTimeout = 3000;

export const Toast = (props: ToastProps) => {
    //const [anchorEl, setAnchorEl] = useState(null);
    //const popoverAnchorEl = useRef(null);
    const mytoast = useRef<HTMLDivElement>(null);
    /************************************************************************************
     * 
    *************************************************************************************/
    useEffect(() => {
        if (props.open) {
            handleOpen();
        }
    });
    /************************************************************************************
     * 
    *************************************************************************************/
    const OnVisible = (entries:any) => {
        //const [ entry ] = entries
        //setIsVisible(entry.isIntersecting)
        //  GoA styling 
        const page = mytoast.current;// document.getElementsByClassName('myToast')[0];
        if(!wdpUtil.isNullorUndefined(page)){
        const callout = page!.getElementsByTagName('goa-callout')[0];
        let shadow = callout?.shadowRoot;
            if (shadow?.children !== undefined) {
                const calloutNoti = shadow?.querySelectorAll(".notification")[0];
                if(calloutNoti){
                    (calloutNoti as HTMLElement).style.marginBottom='0';
                }
            }
        }
    }
    /************************************************************************************
     * 
    *************************************************************************************/
    const handleOpen = () => {
        setTimeout(handleClose, (props.timeout as number) | defualtTimeout);
    };
    /************************************************************************************
     * 
    *************************************************************************************/
    const handleClose = () => {
        if (typeof (props.onClose) === "function") {
            props.onClose();
        }
    };
    /************************************************************************************
     * 
    *************************************************************************************/
    // call custom Hook
    // the need: 
    // Goa Component Shado JS code is enforceing the callout to have a margin bottom 
    // and this is causing a empty space inside the main ShadowBox we added to the toast 
    // the solution:
    // add a custom hook to catch the OnVisible event and then remove the margin 
    useIsVisible(mytoast,OnVisible);
    /************************************************************************************
     * 
    *************************************************************************************/
    if(props.open){
        return (
            <div className="myToast" ref={mytoast}>
                {props.children}
            </div>
        )
   }
   return(<></>);
}
