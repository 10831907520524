//import { isDate, parse } from "date-fns";
import dayjs  from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { tz } from "../../common/constants";
import { RoleType } from '../enums';
import { RolePlanInfo } from '../models/roleInfo';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(tz);
export  class wdpUtil {
  
  static isNullorUndefined(obj: any): boolean {
    return (obj === null || obj === undefined);
  }

  static sortData(sortBy: string, sortDir: number, data: any): any[] {
    const _data = [...data];
    if (data != null && data.length > 0) {
      _data.sort((a: any, b: any) => {
        let aval = a[sortBy];
        let bval = b[sortBy];

        aval = this.isNullorUndefined(a[sortBy]) ? '' : a[sortBy];
        bval = this.isNullorUndefined(b[sortBy]) ? '' : b[sortBy];


        const parts = sortBy.split('.');
        if (parts !== null && parts.length === 2) {
          // handle nested value
          aval = this.isNullorUndefined(a[parts[0]]) ? '' : this.isNullorUndefined(a[parts[0]][parts[1]]) ? '' : a[parts[0]][parts[1]];
          bval = this.isNullorUndefined(b[parts[0]]) ? '' : this.isNullorUndefined(b[parts[0]][parts[1]]) ? '' : b[parts[0]][parts[1]];
        }
        // assuming aval and bval are string
        let res=1;
        if(typeof aval === 'string' && typeof bval === 'string'){
          res = (aval.toLowerCase() > bval.toLowerCase()) ? 1 : -1;
        }

        else{
          res = (aval > bval) ? 1 : -1;
        }
        return res * sortDir;
      });
    }
    return _data;
  }

  static deepCopy(src:any){
    const asStr = JSON.stringify(src);
    return JSON.parse(asStr);
  }

  static formatDate(dt:string|null,ret:string=''){
    let res = ret;
    if(!wdpUtil.isNullorUndefined(dt)){
      const d = new Date(dt!);
      if(!wdpUtil.isNullorUndefined(d)){
        res =  d.toISOString().split('T')[0];
      }
    }
    return res;
  }
  static daysDiff(dt1:Date,dt2:Date):number{
    if(wdpUtil.isNullorUndefined(dt1) || wdpUtil.isNullorUndefined(dt2)){
      return 0;
    }
    const dt1Timestamp = dt1.getTime();
    const dt2Timestamp = dt2.getTime();
    const dif = dt2Timestamp - dt1Timestamp;
    var daysDifference = Math.round(dif / (1000 * 3600 * 24));
    return daysDifference;
  }
  static isDST(val:string|null) {
    if(val === null){
      return 0;
    }
     const utcTime = dayjs(val);
     const Edmonton= dayjs(val).tz();
     const DST = Edmonton.get('day') <  utcTime.get('day');
    return (DST) ? 1 : 0;
  }

  /*****************************************************************************************
   * 
   * Current roles: Roles currently active for the user, defined by today’s date is between Granted Start and Granted end date (if provided). OR today’s date is after/same as Granted start date when there is no Granted end date. 
   * Future roles: Roles with a Granted start date in the future.
   * Grace-expired  (today is 1-7 days after the Granted end date) 
   * expired byeond 7 days
   * avilable 
   * return  { available = 0, current, future, graceExp, expired }
  ******************************************************************************************/
  static getRoleTyle(myrole: RolePlanInfo) {
    const todayDt  = dayjs().tz().startOf('day');
    
    //const myrole = RolesMainList.find((role) =>{role.roleId === roleId})
    if (!wdpUtil.isNullorUndefined(myrole)) {
      // const gsDt = wdpUtil.isNullorUndefined(myrole?.grantedStart) ? null : new Date(myrole?.grantedStart);
      // const geDt = wdpUtil.isNullorUndefined(myrole?.grantedEnd) ? null : new Date(myrole?.grantedEnd!);
      // if(gsDt)gsDt.setHours(0,0,0,0);
      // if(geDt)geDt.setHours(0,0,0,0);
      // Samerconst gsDt = myrole?.grantedStart ?  zonedTimeToUtc(startOfDay(new Date(myrole?.grantedStart)),tz) : null;
      // Samerconst geDt = myrole?.grantedEnd ? zonedTimeToUtc(startOfDay(new Date(myrole?.grantedEnd)),tz):null;
      
      const gsDt = myrole?.grantedStart ? dayjs(myrole?.grantedStart).tz().startOf('day') : null;
      const geDt = myrole?.grantedEnd ?   dayjs(myrole?.grantedEnd).tz().startOf('day'):null;

      if (gsDt !== null) {
        // future
        if (gsDt.toDate() > todayDt.toDate()) {
          return RoleType.future;
        }
        // Grace-expired and expired
        if (geDt !== null) {

          if (todayDt.toDate() >= geDt.toDate()) {
            var daysDifference = wdpUtil.daysDiff(geDt.toDate(),todayDt.toDate());
            const expPeriod = process.env.REACT_APP_ENV === 'prod' ? 7 : 1;
            if (daysDifference > expPeriod) {
              return RoleType.expired;
            } else {
              return RoleType.graceExp;
            }
          }
        }
        // current
        if (todayDt.toDate() >= gsDt.toDate()) {
          if ((geDt === null) || (geDt !== null && todayDt.toDate() < geDt.toDate())) {
            return RoleType.current
          }
        }
      }
    }
    return RoleType.available;
  }
}
