import './Users.scss'
import { useState, useEffect, useRef, useCallback } from "react";
import { GoAInputText, GoABlock, GoADropdown, GoADropdownItem, GoASpacer, GoAPagination, GoAIconButton, GoATable, GoATableSortDirection, GoACircularProgress } from "@abgov/react-components"
import { Link } from "react-router-dom";
import { UserInfo } from '../../common/models/userInfo'
import ApiService from "../../services/ApiService";
import { WdpTable } from '../../components/wdpTable/WdpTable';
import { wdpUtil } from "../../common/utils/util";
import { useAuth } from 'react-oidc-context';
const width = 'minmax(min-content,1fr)';
const defSortDir: GoATableSortDirection = 'asc'
const tableHeaders = [
  { text: "Username (email)", name: 'email', width: `${width}`, sortDir: defSortDir },
  { text: "First name", name: 'firstName', width: `${width}` },
  { text: "Middle name", name: 'middleName', width: `${width}` },
  { text: "Last name", name: 'lastName', width: `${width}` },
  { text: "Display name", name: 'displayName', width: `${width}` },
  { text: "Organization", name: 'organization', width: `${width}` },
  { text: "Username administered by", name: 'usernameAdministeredBy', width: `${width}` },
  { text: "Access start date", name: 'accessStartDate', width: `${width}` },
  { text: "Access end date", name: 'accessEndDate', width: `${width}` },
  { text: "Phone", name: 'phone', width: `${width}` }

];
const minSearchChars = 3;
export const Users = (props:any) => {
  const [abortControllers, setAbortControllers] = useState<AbortController[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // authentication object
  const auth = useAuth();
  // main dataset coming from the backend
  const [mainUsersList, setMainUsers] = useState(Array<UserInfo>);
  
  // page number
  const pageNo = useRef(0);  //"0 instead of 1" this is workaround  to fix pagination pageNo dropdown not being populate first time
  //count per page
  const perPage = useRef(10);
  // total in the DB
  const mainUsersListCount = useRef(0);
  const  previewUsersListCount = useRef(0);
  //const [sortBy,setSortBy]  = useState('email');
  //const [sortDir,setSortDir] = useState<GoATableSortDirection>('asc');
  const sorting = useRef<{by:string,dir:GoATableSortDirection}>({by:'email',dir:'asc'});
  
  
  const [previewUsersList, setPreviewUsersList] = useState(Array<UserInfo>);

  //const [showPreview, setShowPreview] = useState(false);
  const searchStr = useRef('');
  // preview Table
  const previewTableWrapElement = useRef<HTMLDivElement | null>(null);
  const searchWrap = useRef<HTMLDivElement | null>(null);
  
  /****************************************************************************************
  // Load User call back function
  ****************************************************************************************/
  const loadUsers = useCallback((preview: boolean) =>{
    
    // parameters
    const _crit     = searchStr.current;
    const _sortCol  = sorting.current.by;
    const _sortDir  = sorting.current.dir;
    const _pageNo   = pageNo.current === 0 ? 1 :pageNo.current; // this is workaround  to fix pagination pageNo dropdown not being populate first time
    const _perPage  = perPage.current;

  
    if(!preview){
      setIsLoading(true);
    }
    // abort ongoing request
    abortControllers.forEach(controller => controller.abort());
    setAbortControllers([]);

    const controller = new AbortController();
    setAbortControllers([...abortControllers, controller]);
    ApiService.getUsers(auth.user?.access_token as string, _crit,_sortCol,_sortDir, _pageNo,_perPage,controller).then((res:any) => {
      
      if (res != null && res.rows != null && Array.isArray(res.rows)) {
        
        if (preview) {
          setPreviewUsersList(res.rows);
          //setShowPreview(true);
          previewUsersListCount.current = res.count;
        }
        else {
          mainUsersListCount.current = res.count;
          // save in the main list
          setMainUsers(res.rows);
          // save current page
          //setPageUsers(res.slice(0, perPage))
          //setShowPreview(false);
          //previewUsersListCount.current = -1; // close PreviewMode
          exitPreviewMode();
        }
      } else {
        //setShowPreview(false);
        //previewUsersListCount.current = -1; // close PreviewMode
        exitPreviewMode();
      }
    }).catch((error) => {
      console.log(error);
    }).finally(() => {  setIsLoading(false) });
  }, [abortControllers, auth.user?.access_token]);

  /****************************************************************************************
   *  get the data from the backend On sorting and ,pagination
  /****************************************************************************************/
  useEffect(() => {
    loadUsers(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /****************************************************************************************
  *  get the data from the backend On Searching
  /****************************************************************************************/
  function OnSearchChange(value:string){

    searchStr.current = value;
    if (searchStr.current.length === 0) {
      //setPreviewUsersList([]);
      //previewUsersListCount.current = -1; // close PreviewMode
      exitPreviewMode();
      //setShowPreview(false);
      // reset sorting and reload
      sorting.current = {by:'email',dir:'asc'}; 
      loadUsers(false);
      
    } else if ((searchStr.current.length < minSearchChars)) {
      //setPreviewUsersList([]);
      //previewUsersListCount.current=0;
      exitPreviewMode();
    } else if ((searchStr.current.length >= minSearchChars)) {
      pageNo.current = 1;
      loadUsers(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }
  /****************************************************************************************
   * styling after every rendering
  *****************************************************************************************/
  useEffect(() => {
    //  GoA styling 
    //let elem = document.getElementsByTagName getElementsByClassName('trailing-content')[0];
    const page = document.getElementsByClassName('users-content')[0];
    if (wdpUtil.isNullorUndefined(page))
      return;
    var elems = page.querySelectorAll('[slot="trailingContent"]');
    (elems[0] as HTMLElement).style.backgroundColor = 'transparent';// setAttribute("style","background-color:transparent");
    (elems[0] as HTMLElement).style.borderWidth = '0px';
    var wrap = searchWrap.current;
    if (wrap !== undefined && wrap instanceof HTMLDivElement) {
      const w = wrap.clientWidth;
      // get search Inputfield position
      if (previewTableWrapElement.current) {
        if (previewTableWrapElement.current!.style.visibility === '' || previewTableWrapElement.current!.style.visibility === 'hidden') {
          //previewTableWrapElement.current!.style.left = `${Math.floor(wrap.clientLeft - 300)}px`;
          //previewTableWrapElement.current!.style.top = `${Math.floor(wrap.clientTop + 55)}px`;
          //previewTableWrapElement.current!.style.right = `0px`;
          //previewTableWrapElement.current!.style.bottom = `0px`;
          previewTableWrapElement.current!.style.width = `${Math.floor(w)}px`;
          previewTableWrapElement.current!.style.minWidth = `${Math.floor(w)}px`;
        }
      }
      //var inp = searchWrap.current.getElementsByTagName('goa-input')[0];
    }
  });
  /****************************************************************************
   * 
   * @param newPage 
  ****************************************************************************/
  function OnPaginationChange(newPage: any) {
    // if (newPage >= 1) {
    //   const offset = (newPage - 1) * perPage;
    //   const _users = mainUsersList.slice(offset, offset + perPage)
    //   setPageNo(newPage);
    //   setPageUsers(_users);
    // }
    if (newPage >= 1) {
        //setPageNo(newPage);
        pageNo.current = newPage;
        loadUsers(false);
    }
  }
  /****************************************************************************
   * 
   * @param name 
   * @param value 
  ****************************************************************************/
  function changePerPage(name: any, value: any) {
    const newPerPage = parseInt(value, 10);
    // const offset = (pageNo - 1) * newPerPage;
    // const _users = mainUsersList.slice(offset, offset + newPerPage);
    // setPageUsers(_users);
    //setPerPage(newPerPage);
    if(newPerPage >=1){
      
      // reset page No .back to 1
      pageNo.current = 1;
      

      perPage.current = newPerPage;
      loadUsers(false);
    }
  }
  /**********************************************************************************
   * @param sortBy 
   * @param sortDir 
  ***********************************************************************************/
  function sortUserData(_sortBy: string,_sortDir: number) {
    //const _users = wdpUtil.sortData(sortBy, sortDir, mainUsersList);
    //setMainUsers(_users);
    // setSortDir(_sortDir===1?"asc":"desc");
    //setSortBy(_sortBy);
    sorting.current = {by:_sortBy,dir:_sortDir===1?"asc":"desc"};
    // reset page No .back to 1
    pageNo.current = 1;
    loadUsers(false);
    
    // this is for debugging
    //const debug = _users.map((item) => {return item?.lastName});
    //console.log(debug);
  }
  /**********************************************************************************
   * 
   * @param users 
   * @returns 
  ***********************************************************************************/
  function populatetableData(_users: any): any[] {
    if (wdpUtil.isNullorUndefined(_users) || !Array.isArray(_users) || _users.length === 0) {
      return []
    }
    return (
      _users.map((user, i) =>
        <tr key={user.userId}>
          <td><Link to={`/user-ctrl/${user.userId}`}><span>{user.email}</span></Link></td>
          <td><span>{user.firstName}</span></td>
          <td><span>{user?.middleName}</span></td>
          <td><span>{user.lastName}</span></td>
          <td><span>{user?.displayName}</span></td>
          <td><span>{user?.organization}</span></td>
          <td><span>{user?.usernameAdministeredBy}</span></td>
          <td><span>{user?.accessStartDate ? (new Date(user?.accessStartDate).toLocaleDateString('en-CA')) : ""}</span></td>
          <td><span>{user?.accessEndDate ? (new Date(user?.accessEndDate).toLocaleDateString('en-CA')) : ""}</span></td>
          <td><span>{user?.phone}</span></td>
        </tr>
      )
    );
  }
  /**********************************************************************************
   * 
  ***********************************************************************************/
  function populatePreviewtableData(): any[] {
    if (wdpUtil.isNullorUndefined(previewUsersList) || !Array.isArray(previewUsersList) || previewUsersList.length === 0) {
      return []
    }
    return (
      previewUsersList.map((user, i) =>
        <tr key={user.userId}>
          <td><Link to={`/user-ctrl/${user.userId}`}><span>{user.email}</span></Link></td>
          <td><span>{user.firstName} {user?.middleName} {user.lastName}</span></td>

        </tr>
      )
    );
  }
  /***************************************************************************************
   * 
   * @param name 
   * @param value 
   **************************************************************************************/
  function onSearchInputChange(name: string, value: string) {
    // event without a change in the content (special characters, arrows, ...)
    if (searchStr.current === value) {
      return;
    }
    OnSearchChange(value);
  }
  /****************************************************************************************
   * 
  *****************************************************************************************/
  function onSearchBtn() {
    // take a copy
    //const temp = wdpUtil.deepCopy(previewUsersList);
    //setMainUsers(temp);
    //setPreviewUsersList([]);
    //previewUsersListCount.current = -1; // close PreviewMode
    exitPreviewMode();
    //setShowPreview(false);
    loadUsers(false);
  }
  /****************************************************************************************
   * 
  *****************************************************************************************/
  function onCloseSearchBtn() {
    exitPreviewMode();
    //setShowPreview(false);
    // loadUsers("", false);
    OnSearchChange('');
  }
  function exitPreviewMode(){
    
    setPreviewUsersList([]);
    previewUsersListCount.current = -1;
  }
  /****************************************************************************************
   * 
  *****************************************************************************************/
  function populateSearchResTitle() {

    if (searchStr && searchStr.current.length >= minSearchChars && !isLoading) {
      // search preview visible ?
      return (<div className='h3'>{previewUsersListCount.current === -1? mainUsersListCount.current:previewUsersListCount.current} result for '{searchStr.current}'</div>);
      /*}
      else{
        // we close the and reset the search preview (user clicked on the searech icon or hit enter)
        return (<div className='h3'>{mainUsersList?.length} result for '{searchStr}'</div>);
      }*/
    }
    else {
      return (<div></div>);
    }
  }

  function handleKeyEvent(event: any) {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      onSearchBtn();
    }
  }
///////////////////////////////////////////////////////////////////////////
  // html part
  return (
    <div className='users-content'>
      <section className='title-section'>
        <h2 className='title'>User maintenance</h2>
        <Link className="link-button" to="/user-ctrl/new"> Create new user +</Link>
      </section>
      <section className='search-section'>
        {
          populateSearchResTitle()
        }
        <div className='search-wrap' ref={searchWrap} onKeyDown={handleKeyEvent}>
          <GoAInputText
            name="search"
            placeholder="Search by Email, First name, Last name, Username administered by"
            width='70ch'
            //trailingIcon="search"
            //leadingIcon="close"
            trailingContent={
              <div className='search-buttons-wrap'>
                {(searchStr === null || searchStr.current.length === 0) ?
                  <GoAIconButton icon="search" variant="dark" size="medium" disabled title="Search" onClick={function (): void { onSearchBtn() }} />
                  :
                  <GoAIconButton icon="search" variant="dark" size="medium" title="Search" onClick={function (): void { onSearchBtn() }} />
                }

                {(searchStr === null || searchStr.current.length === 0) ?
                  <div style={{ width: '26px' }}></div> :
                  <GoAIconButton icon="close" variant="dark" size="medium" title="Close" onClick={function (): void { onCloseSearchBtn() }} />
                }


              </div>
            }
            onChange={function (name: string, value: string): void {
              onSearchInputChange(name, value)
            }}
            value={searchStr.current}
          />
          {(previewUsersListCount.current !== -1 && previewUsersList.length) ?
            <div className='search-preview-wrap' ref={previewTableWrapElement}>
              <GoATable width="100%" mb="xl">
                <thead>
                </thead>
                <tbody>
                  {
                    populatePreviewtableData()
                  }
                </tbody>
              </GoATable>
            </div>
            : <div></div>
          }
        </div>
      </section>
      {(!wdpUtil.isNullorUndefined(mainUsersList) && mainUsersList.length) ?
        <div>
          <section className="table-section">
            <WdpTable id={'mainTable'} tableHeaders={tableHeaders} populatetableData={()=> populatetableData(mainUsersList)} sortData={()=>sortUserData} />
          </section>
          <GoABlock alignment="center">
            <GoABlock mb="m" alignment="center" gap="m">
              <span>Show</span>
              <GoADropdown testId='ul-pagination-perpage-dropdown' onChange={changePerPage} value="10" width="8ch">
                <GoADropdownItem value="10"></GoADropdownItem>
                <GoADropdownItem value="15"></GoADropdownItem>
                <GoADropdownItem value="20"></GoADropdownItem>
                <GoADropdownItem value="50"></GoADropdownItem>
                <GoADropdownItem value="100"></GoADropdownItem>
              </GoADropdown>
              <span style={{ whiteSpace: 'nowrap' }}>of {mainUsersListCount.current} items</span>
            </GoABlock>
            <GoASpacer hSpacing="fill" />
            <GoAPagination testId='ul-pagination-info'
              variant={'all'}
              itemCount={mainUsersListCount.current}
              perPageCount={perPage.current}
              pageNumber={pageNo.current}
              onChange={OnPaginationChange}
            />
          </GoABlock>
        </div>
        : (!isLoading) && <h3 style={{ width: '50%', margin: '0 auto', textAlign: 'center' }}>There is no result found</h3>
      }
      {/* Circular Progress as a layer  must be child of the page div */}
      {(isLoading && (!props.testing)) &&  <GoACircularProgress variant="fullscreen" size="large" message="Loading users' information..." visible={true} /> }
    </div>
  );

}
export default Users;