import { useEffect, useRef } from "react";
import { wdpUtil } from "../../common/utils/util";

// type IsVisibleProps = {
//   //options: {},
//   callbackFunction: (entries:any) => void,
//   targetElement:React.RefObject<HTMLDivElement>,
// };

export function useIsVisible(targetElement:React.RefObject<HTMLDivElement>,callbackFunction: (entries:any) => void) {
  const options = {
    root: null,
    rootMargin: "0px",
    threshold:1.0
  }
  const containerRef = useRef(null);
  //const [ isVisible, setIsVisible ] = useState(false)
  useEffect(() => {
      
    let observerRefValue:any = null; // <-- variable to hold ref value

    const observer = new IntersectionObserver(callbackFunction);
      //console.log(targetElement);
      if (targetElement.current !== null){ 
        observer.observe(targetElement.current as Element);
        observerRefValue = targetElement.current; //save it
        
      }
      return () => {
        if(! wdpUtil.isNullorUndefined(observerRefValue) ){  
          observer.unobserve(observerRefValue);
        } // <-- use saved value
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, options]);
}