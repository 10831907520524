import { GoAIcon } from "@abgov/react-components";
import { IconButton, InputAdornment,  Popover } from "@mui/material";
import { DateCalendar, DateField } from "@mui/x-date-pickers";
import React, { useCallback, useEffect } from "react";
import { useRef, useState } from "react";
import { dateFormat,tz } from "../../common/constants";

// type PickerProps = DateFieldProps<Date> & React.RefAttributes<HTMLDivElement> & {icon?:GoAIconType};

// export const DatePicker = React.forwardRef((props: PickerProps, ref) => {
//     const [value, setValue] = useState(props.value);
//     const [iconType, seticonType] = useState(props.icon);
//     const dateRef = useRef(null);
//     const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    
    
//     useEffect(() => {
//         seticonType(props.icon);
//     }, [props.icon]);
    
//     function handlePickerClick(onChange: any){
//         if((iconType !== undefined  && iconType === 'close')){
//             seticonType('calendar');
//             setValue(null);
//             if(onChange !== undefined){
//                 onChange(null);
//             }
//         }
//         else{
//             setAnchorEl(dateRef.current);
//         }
//     };
//     const openPicker = Boolean(anchorEl);
//     const pickerId = 'calendar-popover';

//     const handlePickerClose = (onBlur: any, onChange: any, value: any) => {
//         onBlur();
//         onChange(value);
//         setAnchorEl(null);
//     };
//     return (
//         <>
//             <DateField {...props}
//                 ref={ dateRef}
//                 value={value}
//                 onBlur={props.onBlur}
//                 slotProps={{
//                     textField: {
//                         InputProps: {
//                             endAdornment: (
//                                 <InputAdornment position="end">
//                                     <GoAIconButton  icon={iconType?iconType:'calendar'} variant="nocolor"  onClick={() => handlePickerClick(props.onChange)} aria-label=  {(iconType !== undefined && iconType === 'close')? "reset value" :"open calendar"} />
//                                 </InputAdornment>
//                             ),
//                         },
//                     },
//                 }} />
//             <Popover
//                 id={pickerId}
//                 open={openPicker}
//                 anchorEl={anchorEl}
//                 onClose={(e) => { handlePickerClose(props.onBlur, props.onChange, value); }}
//                 anchorOrigin={{
//                     vertical: 'bottom',
//                     horizontal: 'left',
//                 }}
//                 transformOrigin={{
//                     vertical: 'top',
//                     horizontal: 'left',
//                 }}
//             >
//                  <DateCalendar value={value}
//                     onChange={(value) => { setValue(value); handlePickerClose(props.onBlur, props.onChange, value); }}/>
//                 {/* <GoACalendar value={value as Date} onChange={(name, value) => { setValue(value); handlePickerClose(props.onBlur, props.onChange, value); }} /> */}
//             </Popover>
//             {/* <section>
//             SV: {value == null ? 'null' : value.toISOString()}
//             </section> */}
//         </>
//     )
// })

export const CustomFmF = ({ onChange, onBlur, value, name, error }: any) => {

    const dateRef = useRef<any>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleBlur = useCallback((e: any) => {
        handlePickerClose();
        if (typeof onBlur === 'function') {
            onBlur({ target: { name } })
        }
    }, [name, onBlur]);

    useEffect(() => {
        if (dateRef?.current?.onblur) {
            dateRef.current.onblur = handleBlur;
        }
    }, [dateRef, handleBlur])
    
    const openPicker = Boolean(anchorEl);
    const pickerId = 'calendar-popover';
    const handleChange = (fieldName: any, value: any) => {
        if (typeof onChange === 'function') {
            onChange({ target: { value, name } })
        }
        handlePickerClose()
    };

    const handlePickerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(dateRef.current);
    };

    const handlePickerClose = () => {
        setAnchorEl(null);
    };
    
    return (
        <div className={error ? "date-error": ""}>
            <DateField
                ref={dateRef}
                value={value}
                clearable={true}
                name={name}
                timezone={tz}
                format={dateFormat}
                onBlur={handleBlur}
                onChange={(value) => {
                    const testDate = new Date(value);
                    if (testDate.toString() !== 'Invalid Date') {
                        handleChange(name, value || null)
                    }
                }}
                slotProps={{
                    textField: {
                        InputProps: {
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handlePickerClick} aria-label="open calendar">
                                        <GoAIcon type="calendar" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        },
                    },
                }} />
            <Popover
                id={pickerId}
                open={openPicker}
                anchorEl={anchorEl}
                onClose={handleBlur}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <DateCalendar value={value}
                    onChange={(value) => {
                        handleBlur({ target: { name } });
                        handleChange(name, value);
                    }} />
            </Popover>
            {/* <section>
            Stored value: {value == null ? 'null' : value.format()}
            </section> */}
        </div>
    )
}