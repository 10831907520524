import { GoAFormItem, GoAInput, GoATextarea, GoAChip, GoAIcon, GoAButton, GoACircularProgress, GoAButtonGroup, GoAModal, GoACallout, GoAIconButton } from "@abgov/react-components"
import { useEffect, useState } from "react";
import ApiService from "../../../services/ApiService";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import Checkbox from "@mui/material/Checkbox";
import './roleDetails.scss';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import * as yup from "yup";
import dayjs, { Dayjs } from "dayjs";
import { wdpUtil } from "../../../common/utils/util";
import { useFormik } from "formik";
import { InferType } from "yup";
import { CustomFmF } from "../../../components/datePicker/datePicker";
import { Toast } from "../../../components/toast/toast";
// import { parseRefs } from "json-serialize-refs";

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250,
//         },
//     },
// };
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// interface RoleDefinition {
//     AdminRoleName: string;
//     ChildRoles: RoleDefinition[];
//     // CreatedBy: string;
//     // CreatedOn: Date;
//     Description: string;
//     Id: number;
//     OwnerPermissionName: string;
//     OwnerRoleName: string;
//     ParentRoles: RoleDefinition[];
//     Permissions: any[];
//     Remarks: string;
//     RoleId: string;
//     RoleName: string;
//     RoleStartDate: Date;
//     RoleEndDate: Date;
// }

function rolesToIds(newValue: any[]) {
    const res = [];
    for (let i = 0; i < newValue.length; i++) {
        if (typeof (newValue[i]) === 'object') {
            res.push((newValue[i] as any).id);
        } else {
            res.push(newValue[i])
        }
    }
    return res;
}

const roleSchema =
    yup.object().shape({
        adminRoleName: yup.string(),
        childRoles: yup.array(),
        description: yup.string()
            .required('Cannot be empty. Enter role description'),
        id: yup.number(),
        ownerPermissionName: yup.string().nullable(),
        ownerRoleName: yup.string(),
        grantableByRoles: yup.array().min(1, 'Select role(s) that are authorized to grant this role.'),
        parentRoles: yup.array(),
        permissions: yup.array(),
        remarks: yup.string().nullable(),
        roleId: yup.string().nullable(),
        roleName: yup.string().required('Cannot be empty. Enter role name.')
            .matches(/^[^\s-]*$/, 'Role name should be in a valid format.'),
        roleStartDate: yup.string().typeError('Date is invalid')
            .nullable()
            .required('Start date cannot be empty. Enter a date.')
            .test(
                'is-less-than-endDate',
                'This date cannot be in the past. It can be today or in the future.',
                (value, context) => {

                    const asDt = (value ? dayjs(value).tz().endOf('day') : null);
                    const aeDt = wdpUtil.isNullorUndefined(context.parent.roleEndDate) ? null : dayjs(context.parent.roleEndDate).tz().endOf('day');
                    const isLess = !aeDt || asDt! <= aeDt;
                    return isLess;

                }
            )
            .test(
                'is-greater-than-today',
                'This date must be before or the same as role end date.',
                (value, context) => {
                    context.from = context.from || [];
                    const id = context.from[0].value.userId;
                    let isTodayOrLater = true;
                    if (id === 'new') {
                        const todayDt = dayjs().tz().startOf('day');
                        const asDt = dayjs(value).tz().startOf('day');
                        isTodayOrLater = (asDt >= todayDt);

                    }
                    return isTodayOrLater;
                }
            )
        ,
        roleEndDate: yup.string().typeError('Date is invalid')
            .nullable()
            .test(
                'is-greater-than-startDate',
                'This date must be today, later than role start date, or null.',
                (value, context) => {
                    if (!value) return true;
                    const aeDt = dayjs(value).tz().endOf('day');
                    const asDt = wdpUtil.isNullorUndefined(context.parent.roleStartDate) ? null : dayjs(context.parent.roleStartDate).tz().endOf('day');

                    const isGreater = !asDt || aeDt >= asDt;

                    context.from = context.from || [];
                    const id = context.from[0].value.userId;
                    let isTodayOrLater = true;
                    if (id === 'new') {
                        const todayDt = dayjs().tz().startOf('day');
                        const aeDt = dayjs(value).tz().startOf('day');
                        isTodayOrLater = (aeDt >= todayDt);
                    }
                    return isTodayOrLater && isGreater;
                }
            )
        ,

    }, [['roleStartDate', 'roleEndDate']]);

const initialValues: InferType<typeof roleSchema> = {
    ownerRoleName: 'R_WFAppsRoleOwner',
    adminRoleName: '',
    childRoles: [],
    description: '',
    id: 0,
    ownerPermissionName: '',
    grantableByRoles: [],
    parentRoles: [],
    permissions: [],
    remarks: '',
    roleId: '',
    roleName: '',
    roleStartDate: '',
    roleEndDate: '',
} as any;

export const RoleDetails = () => {
    const auth = useAuth();

    const [expandModalvalue, setExpandModalvalue] = useState<string>("");

    function onChangeExpandModal(name: string, value: string) {
        setExpandModalvalue(value);
    }

    const [expandModalField, setExpandModalField] = useState("");
    const [roleDef, setRoleDef] = useState<any>(initialValues);
    const [roles, setRoles] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [permissions, setPermissions] = useState<any[]>([]);
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMsg, setToastMsg] = useState("");
    const [redirectId, setRedirectId] = useState('');
    const navigate = useNavigate();
    const handleToastOpen = (msg: string) => {
        setToastMsg(msg);
        setToastOpen(true);
    };
    /****************************************************************************************
     * 
    *****************************************************************************************/
    const handleToastClose = () => {
        setToastOpen(false);

        if (roleId === "new" && redirectId) {
            navigate(`/role-management/${redirectId}`);
        }
    };
    const touchFields = () => {
        const touched: any = {};
        for (const p in roleSchema?.fields) {
            touched[p] = true;
        }
        formik.setTouched(touched);
    };

    const onSubmit = (data: any) => {
        const postData = JSON.parse(JSON.stringify(data));
        postData.permissions = JSON.stringify(postData?.permissions);
        if (!postData?.roleEndDate) {
            postData.roleEndDate = null;
        }
        if (roleId === 'new') {
            ApiService.addRole(auth.user?.access_token as string, postData)
                .then((res) => {
                    setRedirectId(res);
                    handleToastOpen("New role created successfully");
                })
                .catch(error => {
                    handleToastOpen("failed to create new role");

                })
        } else {
            ApiService.editRole(auth.user?.access_token as string, postData)
                .then((res) => {
                    handleToastOpen("Role updated successfully");
                })
                .catch(error => {
                    handleToastOpen("failed to update role");
                })
        }
    };

    const { roleId } = useParams();
    const width = "700px";
    const formik = useFormik({
        initialValues,
        validationSchema: roleSchema,
        onSubmit: onSubmit
    });

    function onDateChange(event: any) {
        //console.log(value);
        const who = event.target.name;
        let dt: Dayjs | null = event.target.value;
        if ((dt !== null) && isNaN(dt.toDate().getTime())) {
            // Invalid Date
            dt = null;
        }
        const dtStr = dayjs.utc(dayjs(dt).tz().startOf('day')).toISOString();

        formik.setFieldValue(who, (dt ? dtStr : null), true);
        //formik.handleChang();

    }

    useEffect(() => {
        const controller = new AbortController();
        setIsLoading(true);
        ApiService.getRoleDefs(auth.user?.access_token as string, undefined, 'roleName', 'asc', 1, 100, controller).then((res: any) => {
            setRoles(res.rows);
            ApiService.getPermissions(auth?.user?.access_token as string)
                .then(permRes => {
                    setPermissions(permRes);
                    if (roleId !== 'new') {
                        ApiService.getRoleDefById(auth?.user?.access_token as string, roleId as string)
                            .then(def => {
                                // const json = JSON.stringify(def);
                                // def = parseRefs(json);
                                def.permissions = JSON.parse(def?.permissions || "[]");
                                def.permissions = def.permissions.map((r: any) => permRes.find((item: any) => item.id === r.id));
                                // def.childRoles = def.childRoles.map((r: any) => res.rows.find((item: any) => item.id === r.id));
                                // def.parentRoles = def.parentRoles.map((r: any) => res.rows.find((item: any) => item.id === r.id));
                                // def.grantableByRoles = def.grantableByRoles.map((r: any) => res.rows.find((item: any) => item.id === r.id));
                                def.ownerRoleName = res.rows.find((r: any) => r.id === def.ownerRoleId)?.roleName;
                                def.remarks = def.remarks || '';
                                def.description = def.description || '';
                                setRoleDef({ ...def })
                                formik.setValues(def);
                            }).finally(() => {
                                setIsLoading(false);
                            });
                    } else {
                        setIsLoading(false);

                    }
                });

        }).catch((error) => {
            console.log(error);
        });
        // .finally(() => { setIsLoading(false) });
        //eslint-disable-next-line
    }, [auth?.user?.access_token, roleId]);

    function onExpandField(field: string) {
        setExpandModalField(field);
        const valu = field === "description" ? formik.values.description : formik.values.remarks;
        setExpandModalvalue(valu || '');
    }

    function onCancelExpand() {
        setExpandModalField("");
    }
    function onSaveExpand() {
        setExpandModalField("");// close the dialog
        formik.setFieldValue(expandModalField, expandModalvalue);
        formik.setFieldTouched(expandModalField);
    }
    return (
        <>
            {
                isLoading && <GoACircularProgress variant="fullscreen" size="large" message="Loading page data..." visible={true} />
            }
            {!isLoading && <>
                <section className="breadcrumbs">
                    <Link to="/role-management">
                        <section className="breadcrumbs">
                            Role management
                        </section>
                    </Link>
                    <Link to="#">
                        <section className="breadcrumbs">
                            <GoAIcon size="small" type="arrow-back"></GoAIcon>
                            {formik.values?.roleName}
                        </section>
                    </Link>
                </section>
                <form onSubmit={formik.handleSubmit}>
                    <div className='users-content'>
                        <section className='title-section'>
                            <h2 className='title'>{!formik.values?.roleName ? 'New Role' : formik.values?.roleName}</h2>
                        </section>
                    </div>
                    {/* {JSON.stringify(formik.values?.permissions)} */}
                    <section style={{ width: "1511px" }} >
                        <GoAFormItem mb="m" label="Parent roles">
                            <Autocomplete
                                multiple
                                id="parentRoles"
                                data-testid="rd-parentRoles"
                                options={roles}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.roleName}
                                isOptionEqualToValue={(option, value) => option.id === value}
                                renderTags={(value) => <span>{`${value.length} role(s) selected`}</span>}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props} key={option.id}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.roleName}
                                    </li>
                                )}
                                value={formik.values.parentRoles || []}
                                onChange={(event: any, newValue: any[]) => {
                                    formik.setFieldValue("parentRoles", rolesToIds(newValue))
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="" placeholder="Select Roles" />
                                )}
                                sx={{ width }}
                            />
                            <section className="note">Multiple parent roles can be selected.</section>
                            <section className="chips">
                                {formik.values?.parentRoles?.map((pr: any) =>
                                    <span className="chip" key={`k-${pr}`}>
                                        <GoAChip deletable={true} content={(roles.find(r => r.id === pr))?.roleName} onClick={() => {
                                            const index = (formik.values.parentRoles || []).findIndex((i: any) => i === pr);
                                            let newValue = [...(formik.values.parentRoles || [])];
                                            newValue.splice(index, 1);
                                            formik.setFieldValue("parentRoles", newValue);
                                        }}></GoAChip>
                                    </span>
                                )}
                            </section>
                        </GoAFormItem>
                        <section className="middle-section">

                            <section>
                                <section className="field">
                                    <section className="label-wrapper">
                                        <label htmlFor="roleName">Role name </label>
                                        <span className="required">* <span>(required)</span></span>
                                    </section>
                                    <GoAInput testId="rd-roleName" name="roleName" value={formik.values?.roleName} width={width}
                                        onBlur={(name) => formik.setFieldTouched(name)}
                                        error={formik.touched.roleName && Boolean(formik.errors.roleName)}
                                        onChange={(name, value) => { formik.setFieldValue(name, value) }}></GoAInput>
                                    <section className="note">Shall not contain empty spaces and dash symbols.</section>
                                    {formik.touched.roleName && Boolean(formik.errors.roleName) &&
                                        <section className="error">
                                            <GoAIcon type="warning" theme="filled" size="small" />
                                            <span > {formik.errors.roleName}</span>
                                        </section>
                                    }
                                </section>

                                <section className="field">
                                    <section className="label-wrapper">
                                        <label htmlFor="grantableByRoles">Grantable by </label>
                                        <span className="required">* <span>(required)</span></span>
                                    </section>
                                    <Autocomplete
                                        multiple
                                        id="grantableByRoles"
                                        data-testid="rd-grantableByRoles"
                                        options={roles}
                                        isOptionEqualToValue={(option, value) => option.id === value}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.roleName}
                                        renderTags={(value) => <span>{`${value.length} role(s) selected`}</span>}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props} key={option.id}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.roleName}
                                            </li>
                                        )}
                                        value={formik.values.grantableByRoles || []}
                                        onChange={(event: any, newValue: any[]) => {
                                            formik.setFieldValue("grantableByRoles", rolesToIds(newValue))
                                        }}
                                        onBlur={() => formik.setFieldTouched('grantableByRoles', true, true)}
                                        renderInput={(params) => (
                                            <TextField {...params} error={formik.touched.grantableByRoles && Boolean(formik.errors.grantableByRoles)} label="" placeholder="Select Roles" />
                                        )}
                                        sx={{ width }}
                                    />
                                    <section className="chips">
                                        {formik.values?.grantableByRoles?.map((pr: any) =>
                                            <span className="chip" key={`k-${pr}`}>
                                                <GoAChip deletable={true} content={(roles.find(r => r.id === pr))?.roleName} onClick={() => {
                                                    const index = (formik.values.grantableByRoles || []).findIndex((i: any) => i === pr);
                                                    let newValue = [...(formik.values.grantableByRoles || [])];
                                                    newValue.splice(index, 1);
                                                    formik.setFieldValue("grantableByRoles", newValue);
                                                    formik.setFieldTouched('grantableByRoles', true, true)
                                                }}></GoAChip>
                                            </span>
                                        )}
                                    </section>
                                    {formik.touched.grantableByRoles && Boolean(formik.errors.grantableByRoles) &&
                                        <section className="error">
                                            <GoAIcon type="warning" theme="filled" size="small" />
                                            <span > {formik.errors.grantableByRoles}</span>
                                        </section>
                                    }
                                </section>

                                <section className="field">
                                    <section className="label-wrapper">
                                        <label htmlFor="ownerRoleName">Owned by </label>
                                        <span className="required">* <span>(required)</span></span>
                                    </section>
                                    <GoAInput testId="ownerRoleName" name="ownerRoleName" value={formik.values?.ownerRoleName} width={width}
                                        onChange={(name, value) => formik.setFieldValue(name, value)} disabled></GoAInput>
                                    <section className="note">Shall not contain empty spaces and dash symbols.</section>
                                    {formik.touched.ownerRoleName && Boolean(formik.errors.ownerRoleName) &&
                                        <section className="error">
                                            <GoAIcon type="warning" theme="filled" size="small" />
                                            <span > {formik.errors.ownerRoleName}</span>
                                        </section>
                                    }
                                </section>

                                <section className="field">
                                    <section className="label-wrapper">
                                        <label htmlFor="permissions">Permissions </label>
                                    </section>
                                    <Autocomplete
                                        multiple
                                        limitTags={2}
                                        id="premissions"
                                        data-testid="rd-permissions"
                                        options={permissions}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => `(${option.clientName}) ${option.name}`}
                                        renderTags={(value) => <span>{`${value.length} permissions(s) selected`}</span>}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props} key={option.id}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {`(${option.clientName}) ${option.name}`}
                                            </li>
                                        )}
                                        value={formik.values?.permissions || []}
                                        onBlur={() => { formik.setFieldTouched("permissions", true, true) }}
                                        onChange={(event: any, newValue: any[]) => {
                                            formik.setFieldValue("permissions", newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} error={Boolean(formik.errors.permissions && formik.touched.permissions)} label="" placeholder="Select Permissions" />
                                        )}
                                        sx={{ width }}
                                    />
                                    <section className="note">Multiple permissions can be selected.</section>
                                    {formik.errors.permissions && formik.touched.permissions &&
                                        <section className="error">
                                            <GoAIcon type="warning" theme="filled" size="small" />
                                            <span > {formik.errors.permissions}</span>
                                        </section>
                                    }
                                    <section className="chips">
                                        {formik.values?.permissions?.map((pr: any) =>
                                            <span className="chip" key={`k-${pr?.id || pr}`}>
                                                <GoAChip deletable={true} content={pr?.name} onClick={() => {
                                                    const index = (formik.values.permissions || []).findIndex((i: any) => i === pr);
                                                    let newValue = [...(formik.values.permissions || [])];
                                                    newValue.splice(index, 1);
                                                    formik.setFieldTouched('permissions', true, true);
                                                    formik.setFieldValue("permissions", newValue);
                                                }}></GoAChip>
                                            </span>
                                        )}
                                    </section>


                                </section>
                            </section>
                            <section>
                                <section className="field" onClick={() => onExpandField('description')}>
                                    <section className="label-wrapper">
                                        <label htmlFor="description">Role Description</label>
                                        <span className="required"> * <span>(required)</span></span>
                                    </section>

                                    <GoATextarea testId="description" name="description" value={formik.values?.description}

                                        onChange={(name, value) => { formik.setFieldValue(name, value); formik.setFieldTouched(name) }} width={width}></GoATextarea>
                                    <section className="note">Describe the role for administrators to reference while selecting/comparing roles.</section>
                                    {formik.touched.description && Boolean(formik.errors.description) &&
                                        <section className="error">
                                            <GoAIcon type="warning" theme="filled" size="small" />
                                            <span > {formik.errors.description}</span>
                                        </section>
                                    }
                                </section>

                                <div className="date-grid field">
                                    <section>
                                        <section className="label-wrapper">
                                            <label htmlFor="roleStartDate">Role start date</label>
                                            <span className="required"> * <span>(required)</span></span>
                                        </section>
                                        <div>
                                            <section className="date-field">
                                                <CustomFmF name="roleStartDate"
                                                    value={(formik.values.roleStartDate ? dayjs(formik.values.roleStartDate).tz() : null)}
                                                    //onChange={formik.handleChange}
                                                    onChange={(event: any) => onDateChange(event)}
                                                    onBlur={formik.handleBlur}
                                                // error={aTouched && aERrror}
                                                />
                                            </section>
                                            {/* <span className="sub-text">00:00:00&nbsp;AM</span> */}
                                        </div>
                                        {formik.touched.roleStartDate && formik.errors.roleStartDate &&
                                            <section className="error">
                                                <GoAIcon type="warning" theme="filled" size="small" />
                                                <span > {formik.errors.roleStartDate as any}</span>
                                            </section>
                                        }
                                    </section>
                                    <section>
                                        <section className="label-wrapper">
                                            <label htmlFor="roleEndDate">Role end date</label>
                                        </section>
                                        <div>
                                            <section className="date-field">
                                                <CustomFmF name="roleEndDate" data-test-id="test"
                                                    value={(formik.values.roleEndDate ? dayjs(formik.values.roleEndDate).tz() : null)}
                                                    //onChange={formik.handleChange}
                                                    onChange={(event: any) => onDateChange(event)}
                                                    onBlur={formik.handleBlur}
                                                // error={aTouched && aERrror}
                                                />
                                            </section>
                                            {/* <span className="sub-text">23:59:59&nbsp;PM</span> */}
                                        </div>
                                        {formik.touched.roleEndDate && formik.errors.roleEndDate &&
                                            <section className="error">
                                                <GoAIcon type="warning" theme="filled" size="small" />
                                                <span > {formik.errors.roleEndDate as any}</span>
                                            </section>
                                        }
                                    </section>
                                </div>
                                <section onClick={() => onExpandField('remarks')}>
                                    <GoAFormItem label="Remarks">
                                        <GoATextarea testId="remarks" name="remarks" value={formik.values?.remarks || ''} onChange={(name, value) => formik.setFieldValue(name, value)} width={width}></GoATextarea>
                                        <section className="note">Add notes related to the current role.</section>
                                    </GoAFormItem>
                                </section>
                            </section>
                        </section>
                        <GoAFormItem label="Child roles">
                            <Autocomplete
                                multiple
                                limitTags={2}
                                id="childRoles"
                                data-testid="rd-childRoles"
                                options={roles}
                                isOptionEqualToValue={(option, value) => option.id === value}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.roleName}
                                renderTags={(value) => <span>{`${formik.values?.childRoles?.length || 0} role(s) selected`}</span>}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props} key={option.id}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.roleName}
                                    </li>
                                )}
                                value={formik.values?.childRoles || []}
                                onChange={(event: any, newValue: any[]) => {
                                    formik.setFieldValue("childRoles", rolesToIds(newValue));
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="" placeholder="Select Roles" />
                                )}
                                sx={{ width }}
                            />
                            <section className="note">Multiple child roles can be selected.</section>
                            <section className="chips">
                                {formik.values?.childRoles?.map((pr: any) =>
                                    <span className="chip" key={`k-${pr}`}>
                                        <GoAChip deletable={true} content={(roles.find(r => r.id === pr))?.roleName} onClick={() => {
                                            const index = (formik.values.childRoles || []).findIndex((i: any) => i === pr);
                                            let newValue = [...(formik.values.childRoles || [])];
                                            newValue.splice(index, 1);
                                            formik.setFieldValue("childRoles", newValue);
                                        }}></GoAChip>
                                    </span>
                                )}
                            </section>
                        </GoAFormItem>
                        <section className="save-buttons">
                            <GoAButton ml="s" type="submit" onClick={() => { touchFields(); formik.handleSubmit() }} >
                                {roleId === 'new' ? 'Create' : 'Save'}
                            </GoAButton>
                            <GoAButton type="secondary" onClick={() => { formik.setValues(roleDef) }} >
                                Clear
                            </GoAButton>
                        </section>
                    </section>
                </form>

                {/* Modals section  */}
                <Toast open={toastOpen} onClose={handleToastClose} >
                    <GoACallout type="success">
                        <div className="toastContent">
                            <span>{toastMsg}</span>
                            <GoAIconButton icon="close" onClick={() => {
                                handleToastClose();
                            }}></GoAIconButton>
                        </div>
                    </GoACallout>
                </Toast>
                <GoAModal heading={expandModalField === 'description' ? 'Role description' : 'Role remarks'} open={expandModalField !== ''} width="50vw"
                    actions={
                        <GoAButtonGroup alignment="end">
                            <GoAButton testId='rm-expand-modal-cancel' type='secondary' onClick={() => { onCancelExpand(); }}>Cancel</GoAButton>
                            <GoAButton testId='rm-expand-modal-save' type='primary' onClick={() => { onSaveExpand(); }} >Save changes</GoAButton>
                        </GoAButtonGroup>
                    }>
                    <div className="modal-content">
                        <p>Fill in or update the information</p>
                        <section className="label-wrapper">
                            <label htmlFor="rm-expand-modal-txt">{expandModalField === 'description' ? 'Role description' : 'Role remarks'}</label>
                            {expandModalField === 'description' && <span className="required"> * <span>(required)</span></span>}
                        </section>
                        <GoAFormItem
                            labelSize="regular" error=""
                            helpText="Describe the role for administrators to reference while selecting/comparing roles.">
                            <GoATextarea rows={10} width="52.8ch" name="rm-expand-modal-txt" maxCount={1000}
                                value={expandModalvalue}
                                onChange={onChangeExpandModal}></GoATextarea>
                        </GoAFormItem>
                    </div>
                </GoAModal>
            </>}
        </>
    )
}