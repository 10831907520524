import { GoAButton, GoAButtonGroup, GoACallout, GoAChip, GoACircularProgress, GoATable, GoAIcon, GoAInputText, GoAModal, GoACalloutType, GoAIconButton } from "@abgov/react-components";
import './userCtrl.scss'
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { UserDetails } from "./userDetails/userDetails";
import { UserRoles } from "./userRoles/userRoles";
import { UserInfo } from '../../common/models/userInfo'
import ApiService from "../../services/ApiService";
import { wdpUtil } from "../../common/utils/util";
import { useAuth } from "react-oidc-context";
import { Toast } from "../../components/toast/toast";
import { useIsVisible } from "../../components/hooks/useIsVisible";
import { Box, Tabs, Tab } from "@mui/material";
import { RoleType } from "../../common/enums";


const minSearchChars = 3;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export function UserCtrl() {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  //const [fullName, setFullName] = useState({ firstName: '', lastName: '' });
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [userHasRoles, setuserHasRoles] = useState(false);

  const { userId } = useParams();
  const [showSendEmailModal, setShowSendEmailModal] = useState(false);


  const [userSelectedList, setUsersSelectedList] = useState(Array<string>);
  const [usersPreviewList, setUsersPreviewList] = useState(Array<UserInfo>);
  const previewTableWrapElement = useRef<HTMLDivElement | null>(null);
  const searchWrap = useRef<HTMLDivElement | null>(null);

  const [selectedUserId, setSelectedUserId] = useState("");
  const searchStr = useRef('');

  const [showUsersPreview, setShowUsersPreview] = useState(false);
  const [abortControllers, setAbortControllers] = useState<AbortController[]>([]);

  const [toastOpen, setToastOpen] = useState(false);
  const toastMsg = useRef('');
  const toastType = useRef<GoACalloutType | undefined>('success');
  const [emailFormatError, setEmailFormatError] = useState("");
  const [removeLastError, setRemoveLastError] = useState("");
  const myModal = useRef<HTMLDivElement>(null);
  /******************************************************************************************************
   *  here we ask the child component to give us the Roles count for this current selected user
   * we nned that so we can enable/disable the sendEmail button
  *******************************************************************************************************/
  function setRolesCount(rolesCount: number) {
    setuserHasRoles(rolesCount > 0);
  }
  /******************************************************************************************************
   * 
  *******************************************************************************************************/
  function isMandatoryExist(_userInfo: UserInfo) {
    let allExits = true;
    if (wdpUtil.isNullorUndefined(_userInfo?.email) ||
      wdpUtil.isNullorUndefined(_userInfo?.firstName) ||
      wdpUtil.isNullorUndefined(_userInfo?.lastName) ||
      wdpUtil.isNullorUndefined(_userInfo?.organization) ||
      wdpUtil.isNullorUndefined(_userInfo?.usernameAdministeredBy) ||
      wdpUtil.isNullorUndefined(_userInfo?.accessStartDate)) {
      allExits = false;
    }
    return allExits;
  }

  useEffect(() => {
    if (userId !== 'new') {
      setIsLoading(true);
      ApiService.getUsersById(auth.user?.access_token as string, userId as string).then(res => {
        //setFullName({ firstName: res.firstName, lastName: res.lastName });
        // 1- curr user Info
        setUserInfo(res);
        // 2 - init send Email List
        initUserMainList(res);
        // 3 - we need to know id this user has anyGranted Roles
        ApiService.getRolePlans(auth.user?.access_token as string,  userId as string).then((res: any) => {
          if (res != null && Array.isArray(res)) {
            const grRoles = res.filter((role) => {const rt = wdpUtil.getRoleTyle(role);return (rt === RoleType.current || rt === RoleType.future || rt === RoleType.graceExp);});
            setRolesCount(grRoles.length);
          }else{
            console.log(`"UserCtrl: Failed to load the roles for the User ID : ${userId}"`);
          }
        });
      }).finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [auth.user?.access_token, userId]);

  useEffect(() => {
    GoAOverWrite();
  }, [showSendEmailModal]);

  const searchUsers = useCallback((preview: boolean) => {
    // parameters
    const _crit = searchStr.current;
    const _sortCol = 'userName';
    const _sortDir = 'asc';
    const _pageNo = 1;
    const _perPage = 100;

    // abort ongoing request
    abortControllers.forEach(controller => controller.abort());
    setAbortControllers([]);

    const controller = new AbortController();
    setAbortControllers([...abortControllers, controller]);
    ApiService.getUsers(auth.user?.access_token as string, _crit, _sortCol, _sortDir, _pageNo, _perPage, controller).then((res: any) => {

      if (res != null && res.rows != null && Array.isArray(res.rows)) {
        setUsersPreviewList(res.rows);
        setShowUsersPreview(res.rows.length !== 0);
      } else {
        setShowUsersPreview(false);
      }
    }).catch((error) => {
      console.log(error);
    }).finally(() => { setIsLoading(false) });
  }, [abortControllers, auth.user?.access_token]);
  /******************************************************************************************************
   * 
  *******************************************************************************************************/
  function GoAOverWrite() {

    const page = document.getElementsByClassName('userCtrl-content')[0];
    // make GoInput responsive
    const goaInput = page.querySelectorAll('goa-input')[0];
    if (!wdpUtil.isNullorUndefined(goaInput)) {
      let shadow = goaInput?.shadowRoot;
      if (shadow?.children !== undefined) {
        const contentElem = shadow?.querySelectorAll('.container')[0];
        if (contentElem !== undefined) {
          (contentElem as HTMLElement).style.setProperty("width", "100%", "important");
        }
      }
    }
    /////
    var wrap = searchWrap.current;
    if (wrap !== undefined && wrap instanceof HTMLDivElement) {
      const w = wrap.clientWidth;
      // get search Inputfield position
      if (previewTableWrapElement.current) {
        //if (previewULWrapElement.current!.style.visibility === '' || previewULWrapElement.current!.style.visibility === 'hidden') {
        previewTableWrapElement.current!.style.width = `${Math.floor(w)}px`;
        previewTableWrapElement.current!.style.minWidth = `${Math.floor(w)}px`;
        //}
      }
    }
  }
  /******************************************************************************************************
   * 
  *******************************************************************************************************/
  function initUserMainList(user: UserInfo) {
    let users: string[] = [user.email];
    setUsersSelectedList(users);
  }
  /******************************************************************************************************
   * 
  *******************************************************************************************************/
  function onSendEmails() {

    const body: string[] = [];
    userSelectedList.forEach((email) => {
      body.push(email);
    });
    var _toastMsg = "";
    var _toastType: GoACalloutType = "success";
    ApiService.sendRoleNotificationEmail(auth.user?.access_token as string, userInfo!.userId, body).then(res => {
      if (res === 'OK') {
        searchStr.current = "";
        _toastMsg = 'Email(s) sent successfully';
      } else {
        _toastType = "emergency";
        _toastMsg = res;
      }
    }).catch((error) => {
      console.log(error);
      _toastType = "emergency";
      _toastMsg = "An error occured while sending the email(s).";
      // if(error.code === "ERR_BAD_REQUEST"){
      //   _toastMsg =  (error.response?.data || error.message).toString() as string;
      // }
    }).finally(() => {
      handleToastOpen(_toastMsg, _toastType);
      setShowUsersPreview(false);
      setShowSendEmailModal(false);
      setIsLoading(false);
    });
  }
  /******************************************************************************************************
   * 
  *******************************************************************************************************/
  function OnCancel() {
    setShowUsersPreview(false);
    setShowSendEmailModal(false);
    searchStr.current = "";
    setEmailFormatError("");
    setRemoveLastError("");
  }

  /******************************************************************************************************
   * 
  *******************************************************************************************************/
  function onRemoveEmail(_email: string) {

    if (userSelectedList.length === 1) {
      setRemoveLastError("At least one email address is required. Please add another email address before deleting this one.");
      return;
    }
    const newArr = userSelectedList.filter((email) => { return (email !== _email) });
    setUsersSelectedList(newArr);
  }
  /******************************************************************************************************
   * 
  *******************************************************************************************************/
  function onAddEmail() {
    const _email = searchStr.current;

    if (!wdpUtil.isNullorUndefined(_email)) {

      const alreadyExist = userSelectedList.find((email) => email === _email);
      if (alreadyExist) {
        return;
      }
      //validate
      const emailRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      const good = emailRegex.test(_email!);
      if (!good) {
        setEmailFormatError("Enter a valid email address");
        return;
      }
      else {
        setEmailFormatError("");
        setRemoveLastError("");
      }
      // good to go
      const newArr = wdpUtil.deepCopy(userSelectedList);
      newArr.push(_email);
      setUsersSelectedList(newArr);
      // reset
      onSearchInputChange("", "");
    }
  }
  /******************************************************************************************************
   * 
  *******************************************************************************************************/
  function onSearchInputChange(name: string, value: string) {
    // event without a change in the content (special characters, arrows, ...)
    setEmailFormatError("");
    if (searchStr.current === value) {
      return;
    }
    searchStr.current = value;
    if (searchStr.current.length === 0) {
      setUsersPreviewList([]);
      setShowUsersPreview(false);
    } else if ((searchStr.current.length < minSearchChars)) {
      setUsersPreviewList([]);
    } else if ((searchStr.current.length >= minSearchChars)) {
      searchUsers(true);
    }
  }
  /******************************************************************************************************
   * 
  *******************************************************************************************************/
  function populateSelectedList() {
    if (wdpUtil.isNullorUndefined(userSelectedList) || !Array.isArray(userSelectedList) || userSelectedList.length === 0) {
      return (<div>
        <span>at least </span>
      </div>);
    }
    return (
      userSelectedList.map((email, i) =>
        <GoAChip key={'selEmail' + i} deletable={true} onClick={() => (onRemoveEmail(email))} content={email}></GoAChip>
      )
    );
  }
  /******************************************************************************************************
   * 
  *******************************************************************************************************/
  function onSelRow(user: UserInfo) {
    setSelectedUserId(user.userId);
    searchStr.current = user.email;
  }
  function populatePreviewList() {
    if (wdpUtil.isNullorUndefined(usersPreviewList) || !Array.isArray(usersPreviewList) || usersPreviewList.length === 0) {
      return []
    }

    return (
      usersPreviewList.map((user, i) =>
        // <li key={'email' + user.userId} >
        //   {/* <Link to={{}} onClick={() => onSearchItemSelect(email)}><span>{email}</span></Link> */}
        //   <span>{user.email}</span>
        // </li>
        <tr
          key={user.userId}
          style={{ background: selectedUserId === user.userId ? "Highlight" : "", color: selectedUserId === user.userId ? "white" : "" }}
          onClick={() => onSelRow(user)}
        >
          <td><span>{`${user?.firstName} (${user.email})`}</span></td>

        </tr>
      )
    );
  }
  /******************************************************************************************************
   * 
  *******************************************************************************************************/
  function handleKeyEvent(event: any) {
    // if (event.code === 'Enter' || event.code === 'NumpadEnter') {
    //   event.preventDefault();
    //   event.stopPropagation();
    //   event.nativeEvent.stopImmediatePropagation();
    //   onSearchInputChange("", searchStr.current,'enter');
    // }
  }
  const handleToastOpen = (msg: string, type: GoACalloutType | undefined = "success") => {
    toastMsg.current = msg;
    toastType.current = type;
    setToastOpen(true);
  };
  /****************************************************************************************
   * 
  *****************************************************************************************/
  const handleToastClose = () => {
    setToastOpen(false);
  };
  // call custom Hook
  const OnVisible = (entries: any) => {
    GoAOverWrite();
  }
  useIsVisible(myModal, OnVisible);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className='userCtrl-content'>
      {isLoading &&
        <div className="spinner">
          <GoACircularProgress variant="fullscreen" size="large" message="Loading user's details..." visible={true} />
        </div>
      }
      {!isLoading &&
        <div className="userCtrl.content">
          <section className="breadcrumbs">
            <Link to="/">
              <section className="breadcrumbs">
                User maintenance
              </section>
            </Link>
            {(userInfo?.firstName || userInfo?.lastName) &&
              <Link to="#">
                <section className="breadcrumbs">
                  <GoAIcon size="small" type="arrow-back"></GoAIcon>
                  {`${userInfo?.firstName || ""} ${userInfo?.middleName || ""} ${userInfo?.lastName || ""}`}
                </section>
              </Link>
            }
          </section>
          <section className="userNameSec">
            <h1>{`${userInfo?.firstName || ""} ${userInfo?.middleName || ""} ${userInfo?.lastName || ""}`}</h1>
            {userId !== 'new' ?
              <GoAButton testId='uc-send-email' type="primary" disabled={!userHasRoles} onClick={() => {

                setShowSendEmailModal(true);
                initUserMainList(userInfo!);
              }}>Send email to user</GoAButton> : <></>
            }
          </section>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab  label="User Details" {...a11yProps(0)} />
              <Tab label="User Roles" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <UserDetails onChange={(data: any) => { setUserInfo(data) }}></UserDetails>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
              {(!wdpUtil.isNullorUndefined(userInfo) && isMandatoryExist(userInfo!) === true) ?
                <UserRoles currUser={userInfo} setRolesCount={setRolesCount}></UserRoles>
                :
                <div style={{ width: '50%', margin: '0 auto' }}>
                  <GoACallout type="emergency" >
                    <h3 >User roles cannot be assigned. Complete and save all required fields on the User Details tab before assigning User roles.</h3>
                  </GoACallout>
                </div>
              }
          </CustomTabPanel>
          {/* <GoATabs initialTab={1} >
            <GoATab heading="User Details">
              <UserDetails onChange={(data: any) => { setUserInfo(data) }}></UserDetails>
            </GoATab>
            <GoATab heading="User Roles">
              {(!wdpUtil.isNullorUndefined(userInfo) && isMandatoryExist(userInfo!) === true) ?
                <UserRoles currUser={userInfo} setRolesCount={setRolesCount}></UserRoles>
                :
                <div style={{ width: '50%', margin: '0 auto' }}>
                  <GoACallout type="emergency" >
                    <h3 >User roles cannot be assigned. Complete and save all required fields on the User Details tab before assigning User roles.</h3>
                  </GoACallout>
                </div>
              }
            </GoATab>
          </GoATabs> */}
        </div>
      }
      {/* Modals section  */}
      <GoAModal heading="Send confirmation email for roles" open={showSendEmailModal} width="40vw" testId="uc-send-email-modal"
        actions={
          <GoAButtonGroup alignment="end">
            <GoAButton testId='uc-success-modal-no' type='secondary' onClick={() => { OnCancel(); }}>Cancel</GoAButton>
            <GoAButton testId='uc-success-modal-yes' type='primary' onClick={() => { onSendEmails(); }} disabled={userSelectedList.length === 0}>Send</GoAButton>
          </GoAButtonGroup>
        }
      >
        <div className="modal-content" ref={myModal}>
          <p>Send an email with confirmation of Roles and Permissions to the following users:</p>
          {
            populateSelectedList()
          }
          {(removeLastError.length > 0) ?
            <div className="error">
              <GoAIcon type="warning" theme="filled" size="small" />
              <span>{removeLastError}</span>
            </div> : <></>
          }

          <section>
            <h3>Additional emails</h3>
          </section>
          <section className='search-section'>
            <div className="search-wrap" ref={searchWrap} onKeyDown={handleKeyEvent}>
              <GoAInputText name="search" placeholder="Search by email, first name, last name"
                onChange={function (name: string, value: string): void {
                  onSearchInputChange(name, value);
                }}
                value={searchStr.current}
              />
              {(emailFormatError.length > 0) ?
                <div className="error">
                  <GoAIcon type="warning" theme="filled" size="small" />
                  <span>{emailFormatError}</span>
                </div> : <></>
              }

              {(showUsersPreview) ?
                <div className='search-preview-wrap' ref={previewTableWrapElement}>
                  <GoATable width="100%" mb="xl">
                    <thead></thead>
                    <tbody>
                      {
                        populatePreviewList()
                      }
                    </tbody>
                  </GoATable>
                </div>
                : <div></div>
              }
            </div>
            <div style={{ display: "inline-block" }}>
              <GoAButton testId='uc-add-email' type="secondary" onClick={() => { onAddEmail(); }}>Add</GoAButton>
            </div>
          </section>
        </div>
      </GoAModal>
      <Toast timeout={2000} open={toastOpen} onClose={handleToastClose}>
        <GoACallout type={toastType.current} >
          <div className="toastContent">
            <span>{toastMsg.current}</span>
            <GoAIconButton icon="close" variant="dark" onClick={() => {
              handleToastClose();
            }}>
            </GoAIconButton>
          </div>
        </GoACallout>
      </Toast>
    </div>
  );
}
