import { useEffect, useState } from "react";
import ApiService from "../services/ApiService";

type Flag = 'RoleManagement'
            | 'Reporting'
            | 'Users';

export const useFeatureFlags = () => {
  const [flagsLoading, setFlagsLoading] = useState(true);
  const [flags, setFlags] = useState<string[]>([]);
  const hasFlag = (flag: Flag) => {
    return flags.indexOf(flag) !== -1;
  }
  useEffect(() => {
    ApiService.getFeatureFlags()
      .then((flags) => {
        setFlags(flags);
      })
      .finally(() => {
        setFlagsLoading(false);
      });
  }, []);

  return { flags, flagsLoading, hasFlag };
};
