import { GoAAppHeader, GoAAppHeaderMenu, GoAButton, GoAButtonGroup, GoACircularProgress, GoAModal, GoAOneColumnLayout, GoAPageBlock } from "@abgov/react-components";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Nav } from "./components/nav/Nav";
import { LoggedOut } from "./pages/loggedOut/LoggedOut";
import { useEffect, useRef, useState } from "react";
import { UserCtrl } from "./pages/userCtrl/userCtrl";
import { useAuth } from "react-oidc-context";
import { Home } from "./pages/home/Home";
import { RoleList } from "./pages/roleManagement/roleList/roleList";
import { RoleDetails } from "./pages/roleManagement/roleDetails.tsx/roleDetails";
import { useFeatureFlags } from "./hooks/useFeatureFlags";
import { hasResourceRole } from "./common/utils/tokenUtil";

const resourceId = 'wildfire-admin-api';
const roleManagerPermission = 'Adm_RoleManager_W';

function App(props: any) {
  const auth = useAuth();
  const { flagsLoading, hasFlag } = useFeatureFlags();
  const [tokenExpiringDlg, setTokenExpiringDlg] = useState(false);


  const logoutTimer = useRef(0);
  // auth.events.addUserLoaded(()=>{
  //   console.log("Access token - user loaded");
  // });



  // auth.events.addAccessTokenExpiring(() => {
  //   console.log("Access token is expiring");

  //   setTokenExpiringDlg(true);

  //   if(logoutTimer === 0){
  //       logoutTimer = window.setTimeout(() => {
  //         alert("logging out");
  //         onLogOut();
  //     }, 120000/*2 minutes*/);
  //   }
  // });
  // auth.events.addAccessTokenExpired(() => {
  //   console.log("Access token is Expired");
  // });

  // auth.events.addSilentRenewError(err => {
  //   console.log(err);
  //   window.clearTimeout(logoutTimer);  
  //   logoutTimer = 0;
  // });

  /**************************************************************************
   * 
  ***************************************************************************/
  useEffect(() => {
    const isLoggedOutPath = window.location.pathname === "/logged-out";
    if (!isLoggedOutPath && !auth.isLoading && !auth.isAuthenticated) {
      if (window.location.search) {
        window.location.href = window.location.origin;
      } else {
        auth.clearStaleState();
        auth.signinRedirect();
      }
    }
    // else if (!isLoggedOutPath && !auth.isLoading && auth.isAuthenticated) {
    //   auth.startSilentRenew();
    // }
  }, [auth]);
  /**************************************************************************
   * 
  ***************************************************************************/
  useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    if (tokenExpiringDlg === false) {
      return auth.events.addAccessTokenExpiring(() => {
        if (logoutTimer.current === 0) {
          setTokenExpiringDlg(true);
          logoutTimer.current = window.setTimeout(() => {
            console.log("auto logging out In " + auth.user?.expires_in);
            onLogOut();
          }, (60000 * 2) /*2 minutes*/);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.events, auth.signinSilent]);

  // useEffect(() => {
  //   if(!auth.isLoading  && !wdpUtil.isNullorUndefined(auth.user) && tokenExpiringDlg === false){
  //     //console.log(parseToken(auth.user?.access_token as string));
  //     console.log("In " +auth.user?.expires_in);
  //     console.log("at " + auth.user?.expires_at);
  //     return auth.events.addAccessTokenExpiring(() => {
  //       console.log("Access token is expiring due to inactivity");
  //       if(logoutTimer.current === 0){
  //         setTokenExpiringDlg(true);
  //         logoutTimer.current = window.setTimeout(() => {
  //           console.log("logging out In " +auth.user?.expires_in);
  //           onLogOut();
  //           }, (60000*1) /*2 minutes*/);
  //       }
  //     });
  //   }
  // }, [auth.user]);
  /**************************************************************************
   * 
  ***************************************************************************/
  useEffect(() => {
    // this is for GoA customization/overwrite 
    const goaH = document.querySelectorAll('goa-app-header')[0];
    if (goaH !== undefined) {
      let shadow = goaH?.shadowRoot;
      const link: HTMLAnchorElement | undefined = shadow?.querySelectorAll('a')[0];
      if (link !== undefined) {
        link.target = '_blank';
      }
    }
  }, []);

  function getMenuTitle() {
    let title = '';
    if (hasFlag('Users') && hasFlag('RoleManagement') && hasFlag('Reporting')) {
      title = 'User access, role management and reporting';
    } else if (hasFlag('Users') && hasFlag('RoleManagement') && !hasFlag('Reporting')) {
      title = 'User access and role management';
    } else if (hasFlag('Users') && !hasFlag('RoleManagement') && hasFlag('Reporting')) {
      title = 'User access and reporting';
    } else if (hasFlag('RoleManagement') && hasFlag('Reporting')) {
      title = 'Role management and reporting';
    } else if (hasFlag('RoleManagement') && !hasFlag('Reporting')) {
      title = 'Role management';
    } else if (hasFlag('Reporting')) {
      title = 'Reporting';
    } else if (hasFlag('Users')) {
      title = 'User access';
    }
    return title;
  }
  /**************************************************************************
   * 
  ***************************************************************************/
  function getPortalUrl(): string {
    let url = 'https://wildfireapps-dev.alberta.ca/';
    if (process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'local') {
      url = 'https://wildfireapps-dev.alberta.ca/';
    }
    else if (process.env.REACT_APP_ENV === 'uat') {
      url = 'https://goa-wdp-wildfire-uat.azurewebsites.net/';
    }
    else if (process.env.REACT_APP_ENV === 'prod') {
      url = 'https://goa-wdp-wildfire.azurewebsites.net/';
    }
    return url;
  }
  /**************************************************************************
   * 
  ***************************************************************************/
  function onLogOut() {
    auth.signoutRedirect({ id_token_hint: auth.user?.id_token }).then(res => {
      alert(res);
    }
    );
    // close the dialog
    setTokenExpiringDlg(false);
    window.clearTimeout(logoutTimer.current);
    logoutTimer.current = 0;
    //auth.events.removeAccessTokenExpiring(()=>{});
  }
  /**************************************************************************
   * 
  ***************************************************************************/
  function onExtendSession() {
    //auth.startSilentRenew(); // this is to enable automatic silinetRenew..but what we need here is manual renew we trigger the refresh using (signinSilent)
    auth.signinSilent();
    setTokenExpiringDlg(false);
    window.clearTimeout(logoutTimer.current);
    logoutTimer.current = 0;
    //auth.events.removeAccessTokenExpiring(()=>{});
  }

  /////////////////////////////////////////////////////////////////////////// 
  return (
    <>
      <BrowserRouter>
        <GoAOneColumnLayout>
          <section slot="header">
            {/* <GoAMicrositeHeader type="alpha" version={ process.env.NODE_ENV } /> */}
            <GoAAppHeader
              url={getPortalUrl()}
              heading="Application Administration"
              maxContentWidth="100%"
            >
              {!auth.isLoading && auth.isAuthenticated && !flagsLoading &&
                <GoAAppHeaderMenu heading={getMenuTitle()} >
                  {hasFlag('Users') && <a href='/'>User maintenance</a>}
                  {hasFlag('RoleManagement') && hasResourceRole(resourceId, roleManagerPermission, auth.user?.access_token as string) && <a href='/role-management'>Role management</a>}
                </GoAAppHeaderMenu>}
              <div>
                {!auth.isLoading && <Nav />}
              </div>
            </GoAAppHeader>
          </section>
          <GoAPageBlock width="100%">
            {!auth.isLoading &&
              <Routes>
                {hasFlag('Users') && <Route path='/' element={<Home />} />}
                {hasFlag('Users') && <Route path='/user-ctrl/:userId' element={<UserCtrl />} />}
                {/* <Route path='/my-account' element={<MyAccount />} /> */}
                <Route path='/logged-out' element={<LoggedOut />} />
                {hasFlag('RoleManagement') &&
                  hasResourceRole(resourceId, roleManagerPermission, auth.user?.access_token as string) &&
                  <Route path='/role-management' element={<RoleList />} />}
                {hasFlag('RoleManagement') &&
                  hasResourceRole(resourceId, roleManagerPermission, auth.user?.access_token as string) &&
                  <Route path='/role-management/:roleId' element={<RoleDetails />} />}
              </Routes>}
            {(auth.isLoading && !props.testing) && <GoACircularProgress variant="fullscreen" size="large" message="Authentication in progress..." visible={true} />}
          </GoAPageBlock>
        </GoAOneColumnLayout>
      </BrowserRouter>

      <GoAModal
        heading="Logout" open={tokenExpiringDlg}
        calloutVariant="emergency"
        role="alertdialog"
        //onClose={() => onLogOut()}
        actions={
          <GoAButtonGroup alignment="end" mt="l">
            <GoAButton type="secondary" onClick={onLogOut}>Log out</GoAButton>
            <GoAButton type="primary" onClick={onExtendSession}>Extend my session</GoAButton>
          </GoAButtonGroup>
        }
      >
        <p>You will be logged out within 2 minutes!</p>
      </GoAModal>

    </>
  );
}

export default App;